import React from 'react';

import { AuthProvider as OIDCProvider } from 'react-oidc-context';
import getOidcClientConfig from '../../../services/auth/auth.config';

import AuthProviderInner from './AuthProviderInner';

const AuthProvider: React.FC = ({ children }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <OIDCProvider {...getOidcClientConfig()}>
    <AuthProviderInner>{children}</AuthProviderInner>
  </OIDCProvider>
);

export default AuthProvider;
