import React from 'react';

import { ProjectInfoProps } from '../../../services/api/domain/projects/projects.types';
import ModalScreen from '../modal';

import * as S from './ProjectInfoSc';

const ProjectInfo: React.FC<ProjectInfoProps> = (projectDetail) => {
    return (
        <>
            {projectDetail ? (
                <S.DetailContainer>
                    
                    <S.InfoContaier>
                        <S.InfoTitle>Projektleitung</S.InfoTitle>
                        <S.ManagerInnerContainer>
                            {
                                JSON.parse(projectDetail.project_manager).avatar !== '' ? 
                                    <S.ManagerImg src={JSON.parse(projectDetail.project_manager).avatar} /> 
                                        : 
                                    <S.ManagerImg src={require("../../../assets/images/default-avatar.png")} />
                            }
                            <div className={"profileHeaderInfo"}>
                                <p className={"managerName"}>{JSON.parse(projectDetail.project_manager).name}</p>
                                <S.MaanagerEmail href={'mailto:' + JSON.parse(projectDetail.project_manager).email}>{JSON.parse(projectDetail.project_manager).email}</S.MaanagerEmail>
                            </div>
                        </S.ManagerInnerContainer>
                    </S.InfoContaier>

                    <S.InfoContainerTitleOuter>
                        <S.InfoContainerTitle>Projekt Infos</S.InfoContainerTitle>
                        <ModalScreen {...projectDetail} />
                    </S.InfoContainerTitleOuter>

                    <S.DetailInnerContainer>
                        <S.InfoContaier>
                            <S.InfoTitle>Kontakt</S.InfoTitle>
                            <div>
                                <p className={"projectContactEmail"}>{projectDetail.contact_email === 'null' ? '' : projectDetail.contact_email}</p>
                                <p className={"projectContactInfo"}>{projectDetail.contact_infos === 'null' ? 'Keine Kontakt Informationen' : projectDetail.contact_infos}</p>
                            </div>
                        </S.InfoContaier>

                        <S.InfoContaier>
                            <S.InfoTitle>Projekt Setup</S.InfoTitle>
                            <div>
                                <p className={"projectSetupConf"}>{projectDetail.project_conf_space === 'null' ? 'No Confluence Space' : <a href={projectDetail.project_conf_space} target={"_blank"} rel={"noreferrer"}>Confluence Space</a>}</p>
                                <p className={"projectSetupContr"}>{projectDetail.project_contract_url === 'null' ? '' : <a href={projectDetail.project_contract_url} target={"_blank"} rel={"noreferrer"}>Contract URL</a>}</p>
                            </div>
                        </S.InfoContaier>

                        <S.InfoContaier>
                            <S.InfoTitle>Projekt Invoice</S.InfoTitle>
                            <div>
                                <p className={"projectSetupConf"}>{projectDetail.project_invoice_contract === 'null' ? ('No Contract sign date') : ('Contract sign date: ' + projectDetail.project_invoice_contract)}</p>
                                <p className={"projectSetupContr"}>{projectDetail.project_invoice_type === 'null' ? 'No Invoice Type defined' : 'Invoicing type: ' + projectDetail.project_invoice_type}</p>
                            </div>
                        </S.InfoContaier>

                        <S.InfoContaier>
                            <S.InfoTitle>Projekt Invoice Fee</S.InfoTitle>
                            <div>
                                <p className={"projectSetupConf"}>Project Full Price: {projectDetail.project_full_price}</p>
                                <p className={"projectSetupConf"}>Architect consultant Fee: {projectDetail.architect_fee}</p>
                                <p className={"projectSetupConf"}>Business consultant Fee: {projectDetail.business_fee}</p>
                                <p className={"projectSetupConf"}>Backend Developer Fee: {projectDetail.backend_fee}</p>
                                <p className={"projectSetupConf"}>Frontend Developer Fee: {projectDetail.frontend_fee}</p>
                                <p className={"projectSetupConf"}>DevOps consultants Fee: {projectDetail.devops_fee}</p>
                            </div>
                        </S.InfoContaier>
                    </S.DetailInnerContainer>
                </S.DetailContainer>   
            ) : (
                <><p>FALSE</p></>
            )}
        </>
    );
};

export default ProjectInfo;
