import tw, { css, styled } from 'twin.macro';

export const RessourcePlanningContainer = styled.div(() => [
    css`
        ${tw`sm:flex-row flex flex-col flex-wrap w-full justify-between overflow-auto`}

        min-width: 400px;
        max-width: 900px;
    `
]);

export const LastUpdatedOuterContainer = styled.div(() => [
    css`
        ${tw`flex flex-row items-center mb-20`}
    `
]);

export const UpdateDataBtn = styled.p(() => [
    css`
    padding: 8px 10px;
    max-width: 300px;
    width: 150px
    height: 40px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #FFF;
    background: #ed7151;
    margin-left: 20px;
    `
]);

export const LastUpdatedContainer = styled.div(() => [
    css`
        && p:first-of-type {
            ${tw`font-bold`}
        }

        && p {
            ${tw`text-2xl text-gray-600`}
        }
    `
]);
