import { UserManagerSettings } from "oidc-client-ts";

const getOidcClientConfig = () => {
  const oidcConfig: UserManagerSettings = {
    authority: `${window._env_.KEYCLOAK_AUTH_URL}/${window._env_.KEYCLOAK_REALM}`,
    client_id: window._env_.KEYCLOAK_CLIENT_ID,
    redirect_uri: window.location.origin,
    scope: "profile email offline_access",
  };

  // ch.phoenix-systems.switch-poc/oidc-callback
  // ch.switch-poc.pheonix-systems/qrcode-callback

  return oidcConfig;
};

export default getOidcClientConfig;
