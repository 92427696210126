import React from 'react';

import { Progress } from 'antd';
import { WorkloadProps } from '../../../services/api/domain/issues/issues.types';

import * as S from './EmployeeProgressSc';

type RessourcePlanningWeekProps = {
    name: string;
    week: string;
    weekData: WorkloadProps[] | undefined;
};

const EmployeeProgress: React.FC<RessourcePlanningWeekProps> = (data) => {

    const getWeekWorkload = () => {
        let weekWorkload = 0;
        if (data.weekData) {
            data.weekData.forEach((workload) => {
                if (workload.name === data.name) {
                    weekWorkload += workload.workload;
                }
            });
        }

        return weekWorkload;
    };
    return (
        <S.EmployeeProgressItem>
            <p>{data.week}</p>
            
            <Progress 
                type="circle" 
                percent={getWeekWorkload()}
                format={() => getWeekWorkload() + '%'}
                strokeColor={
                    getWeekWorkload() > 74 && getWeekWorkload() < 111 ? 
                        '#52c41a' 
                    : 
                    getWeekWorkload() > 110 ? 
                        '#e9521b' 
                    : 
                        '#f9b75f'
                } 
            />
        </S.EmployeeProgressItem>
    );
};

export default EmployeeProgress;
