import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { api_getProjectIssues } from '../../../services/api/domain/issues/issues.queries';
import { Issue } from '../../../services/api/domain/issues/issues.types';
import { api_getProjectInfos } from '../../../services/api/domain/projects/projects.queries';
import { ProjectInfoProps } from '../../../services/api/domain/projects/projects.types';
import { RootState } from '../../../services/store/rootReducer';
import InvoiceProjectFees from '../../ui/invoiceProjectFees';
import IssueTable from '../../ui/issueTable';
import ProjectInfo from '../../ui/projectInfo';

const ProjectInvoice: React.FC = () => {
    const { projects } = useSelector((state: RootState) => state.projects);
    const params = useParams();
    const [projectIssues, setProjectIssues] = useState<Issue[]>([]);
    const [projectDetail, setProjectDetail] = useState<ProjectInfoProps | undefined>(undefined);

    const handleInitProjectInfo = async () => {
        setProjectDetail(undefined);
        if (params?.projectId) {
            const infoData = await api_getProjectInfos(params.projectId);
            setProjectDetail(infoData);
        }
    };

    const hanldeInitProjectIssues = async () => {
        setProjectIssues([]);
        if (params?.projectId) {
            const issueData = await api_getProjectIssues(params.projectId);
            setProjectIssues(issueData);
        }
    }

    useEffect(() => {
        setProjectIssues([]);
        if (params?.projectId && params?.projectId !== undefined) {
            handleInitProjectInfo();
            hanldeInitProjectIssues();
        }
    }, [params, projects]);
    
    return (
        <>
            {projectIssues.length > 0 || projectDetail !== undefined ? (
                <>
                    {projectDetail !== undefined ? (
                        <ProjectInfo {...projectDetail} />
                    ) : (
                        <></>
                    )}
                    
                    {projectIssues.length > 0 && projectDetail !== undefined ? (
                        <>
                            <InvoiceProjectFees issues={projectIssues} projectDetail={projectDetail} />
                            <IssueTable  issues={projectIssues} projectDetail={projectDetail}/>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <p>There are no Project Informations available</p>  
            )}
        </>
    );
};

export default ProjectInvoice;
