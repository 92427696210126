import httpCommon from '../httpCommon';

import {
    Employee,
} from './employee.types';

export const api_getEmployees = async (): Promise<Employee> => {
    const {data} = await httpCommon.get(`/employees`);

    return data;
};

export const api_initEmployees = async () => {
    const {data} = await httpCommon.get(`/employees/init`);

    return data;
}

export const api_deleteEmployees = async () => {
    const {data} = await httpCommon.delete(`/employees`);

    return data;
};
