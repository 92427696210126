import routeConstants from './routes.constants';

const base = routeConstants.base === `${routeConstants.base}`;

/**
  * HOME
  */
const homeRoutes = {
    segment: base,
    path: `/${base}`
};

/*
 * Invoices
 */
const invoiceSegment = 'invoices';
const invoiceProjectSegegment = ':projectId';
const invoicesRoutes = {
    segment: `${invoiceSegment}`,
    path: `/${base}/${invoiceSegment}`,

    project: {
        path: `/${base}/${invoiceSegment}/${invoiceProjectSegegment}`,
        segment: invoiceProjectSegegment
    }
};

/*
 * Workloads
 */
const workloadSegment = 'workloads';
const workloadProjectSegment = 'project';
const workloadsRoutes = {
    segment: `${workloadSegment}`,
    path: `/${base}/${workloadSegment}`,

    employee: {
        path: `/${base}/${workloadSegment}/:empName`,
        segment: ':empName'
    },

    project: {
        path: `/${base}/${workloadSegment}/${workloadProjectSegment}/:projId`,
        segment: 'project/:projId' 
    }
}

const routes = {
    home: homeRoutes,
    invoices: invoicesRoutes,
    workloads: workloadsRoutes
};

export default routes;
