import Table, { ColumnType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Issue } from '../../../services/api/domain/issues/issues.types';
import { RootState } from '../../../services/store/rootReducer';

import * as S from './EmployeeTableSc';

type employeeTableProps = {
    id: string;
    workload: string;
    role: string;
    issue_key: string;
    parent_summary: string;
    workweek: string;
    status: string;
    key: string;
};

type EmployeeProps = {
    name: string;
    avatar: string;
    email: string;
}

const EmployeeTable: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { issues } = useSelector((state: RootState) => state.issues);
    const [tableData, setTableData] = useState<employeeTableProps[]>([]);
    const [tableWeek, setTableWeek] = useState<string[]>([]);
    const [selEmp, setSelEmp] = useState('');
    const params = useParams();

    const columns: ColumnType<employeeTableProps>[] = [
        {
            title: 'Project',
            dataIndex: 'parent_summary',
            key: 'parent_summary',
        },
        {
            title: 'Issue',
            dataIndex: 'issue_key',
            key: 'issue_key',
        },
        {
            title: 'State',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'ROLEissue_key',
        },
        {
            title: 'Workweek',
            dataIndex: 'workweek',
            key: 'workweek',
            filters: [
                ...tableWeek.map(data => ({
                    text: data,
                    value: data,
                }))
            ],
            onFilter: (value, record) => record.workweek === value,
        },
        {
            title: 'Workload',
            dataIndex: 'workload',
            key: 'workload',
        },
    ];

    const getTabelDataWorkload = (workload: string, index: number) => {
        if (workload.includes(',')) {
            return workload.split(',')[index];
        } else {
            return workload;
        }
    };

    const getTableContent = (issue: Issue, role: string) => {
        let issueData = {} as employeeTableProps;

        if (role === 'Frontend Developer') {
            if (issue.frontend_employees !== '[\"null\"]') {
                JSON.parse(issue.frontend_employees).forEach((employee: EmployeeProps, index: number) => {
                    if (employee.name === selEmp) {
                        issueData = {
                            id: issue.issueId,
                            workload: getTabelDataWorkload(issue.frontend_workload, index),
                            role: 'Frontend Developer',
                            issue_key: issue.issue_key,
                            parent_summary: issue.parent_summary,
                            workweek: issue.workweek,
                            status: issue.status,
                            key: issue.issueId
                        };
                    }
                });
            }
        }
        if (role === 'Backend Developer') {
            if (issue.backend_employees !== '[\"null\"]') {
                JSON.parse(issue.backend_employees).forEach((employee: EmployeeProps, index: number) => {
                    if (employee.name === selEmp) {
                        issueData = {
                            id: issue.issueId,
                            workload: getTabelDataWorkload(issue.backend_workload, index),
                            role: 'Backend Developer',
                            issue_key: issue.issue_key,
                            parent_summary: issue.parent_summary,
                            workweek: issue.workweek,
                            status: issue.status,
                            key: issue.issueId
                        };
                    }
                });
            }
        }
        if (role === 'Architect consultant') {
            if (issue.architect_employees !== '[\"null\"]') {
                JSON.parse(issue.architect_employees).forEach((employee: EmployeeProps, index: number) => {
                    if (employee.name === selEmp) {
                        issueData = {
                            id: issue.issueId,
                            workload: getTabelDataWorkload(issue.architect_workload, index),
                            role: 'Architect consultant',
                            issue_key: issue.issue_key,
                            parent_summary: issue.parent_summary,
                            workweek: issue.workweek,
                            status: issue.status,
                            key: issue.issueId
                        };
                    }
                });
            }
        }
        if (role === 'Business consultant') {
            if (issue.business_employees !== '[\"null\"]') {
                JSON.parse(issue.business_employees).forEach((employee: EmployeeProps, index: number) => {
                    if (employee.name === selEmp) {
                        issueData = {
                            id: issue.issueId,
                            workload: getTabelDataWorkload(issue.business_workload, index),
                            role: 'Business consultant',
                            issue_key: issue.issue_key,
                            parent_summary: issue.parent_summary,
                            workweek: issue.workweek,
                            status: issue.status,
                            key: issue.issueId
                        };
                    }
                });
            }
        }
        if (role === 'DevOps consultant') {
            if (issue.devops_employees !== '[\"null\"]') {
                JSON.parse(issue.devops_employees).forEach((employee: EmployeeProps, index: number) => {
                    if (employee.name === selEmp) {
                        issueData = {
                            id: issue.issueId,
                            workload: getTabelDataWorkload(issue.devops_workload, index),
                            role: 'DevOps consultant',
                            issue_key: issue.issue_key,
                            parent_summary: issue.parent_summary,
                            workweek: issue.workweek,
                            status: issue.status,
                            key: issue.issueId
                        };
                    }
                });
            }
        }

        return issueData;
    };

    const getTableData = () => {
        let tableDataArr = [] as employeeTableProps[];
        let tableWeekArr = [] as string[];

        issues.forEach((issue: Issue) => {
            if (issue.architect_employees.includes(selEmp)) {
                tableDataArr.push(getTableContent(issue, 'Architect consultant'));
            }
            if (issue.business_employees.includes(selEmp)) {
                tableDataArr.push(getTableContent(issue, 'Business consultant'));
            }
            if (issue.backend_employees.includes(selEmp)) {
                tableDataArr.push(getTableContent(issue, 'Backend Developer'));
            }
            if (issue.frontend_employees.includes(selEmp)) {
                tableDataArr.push(getTableContent(issue, 'Frontend Developer'));
            }
            if (issue.devops_employees.includes(selEmp)) {
                tableDataArr.push(getTableContent(issue, 'DevOps consultant'));
            }
        });

        for (const i in tableDataArr){
            if (tableWeekArr.indexOf(tableDataArr[i].workweek) === -1){
                tableWeekArr.push(tableDataArr[i].workweek);
            }
        }

        setTableWeek(tableWeekArr);
        setTableData(tableDataArr);
        setIsLoading(false);
    }

    useEffect(() => {
        if (tableData.length === 0 && !isLoading) {
            getTableData();
            setIsLoading(true);
        }
    }, [selEmp]);

    useEffect(() => {
        if (params && params.empName !== undefined) {
            setSelEmp(params.empName);
            setTableData([]);
            setIsLoading(false);
        }
    }, [params]);
    
    return (
        <S.EmployeeTableContainer>
            {tableData.length > 0 && (
                <Table 
                    columns={columns} 
                    dataSource={tableData}
                />
            )}
        </S.EmployeeTableContainer>
    );
};

export default EmployeeTable;
