import { createSlice } from '@reduxjs/toolkit';

export const lastUpdatedSlice = createSlice({
    name: 'lastUpdated',
    initialState: {
        lastUpdated: ''
    },
    reducers: {
        changeLastUpdated: (state, action) => {
            state.lastUpdated = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeLastUpdated } = lastUpdatedSlice.actions

export default lastUpdatedSlice.reducer
