import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Menu, message, Space } from 'antd';
import { useAuth } from 'react-oidc-context';
import React from 'react';
import { api_deleteProjects } from '../../../services/api/domain/projects/projects.queries';
import { api_deleteEmployees } from '../../../services/api/domain/employees/employee.queries';

const PageHeader: React.FC = () => {
    const auth = useAuth();

    const deleteEmployees = async () => {
        await api_deleteEmployees();        
    };
    const deleteProjects = async () => {
        await api_deleteProjects();
    };

    const onClick: MenuProps['onClick'] = ({ key }) => {
        if (key === 'reloadData') {
            deleteEmployees();
            deleteProjects();
            window.location.reload();
        }
    
        if (key === 'logout') {
            auth.signoutRedirect();
        }
    };

    const menu = (
        <Menu
            onClick={onClick}
        >
            <Menu.Item key="reloadData" title="reloadData">
                Empty Database & Reload Data
            </Menu.Item>
            <Menu.Item key="logout" title="logout">
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
            <>
                {console.log(auth)}
                <Dropdown overlay={menu}>
                    <a onClick={e => e.preventDefault()}>
                    <Space>
                        Profile
                        <DownOutlined />
                    </Space>
                    </a>
                </Dropdown>
            </>
        );
};

export default PageHeader;
