import tw, { css, styled } from 'twin.macro';

export const RessourceTableContainer = styled.div(() => [
    css`
        ${tw`sm:mb-48 overflow-auto`}

        min-width: 900px;
        max-width: 1400px;
    `
]);

export const RessourceTable = styled.table(() => [
    css`
        ${tw`w-full`}

        && tr.personCell:hover {
            cursor: pointer;
            color: rgb(233, 82, 27);
            background: #fff;
        }

        && tr{
            display: revert;
        }
        && td{
            text-align: center;
            font-weight: 500;
            margin-right: 50;
            border: 1px solid #ccc;
            width: 7.5%;
            padding: 5px 10px;
        }
        && td:first-of-type {
            width: 40%;
        }
        && td.headt{
            text-align: center;
            font-weight: 700;
        }
        && td.headP{
            text-align: left;
            font-weight: 700;
        }
        && td.overWorkloadTableCell {
            color: red;
        }
    
    `    
]);
