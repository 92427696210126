import { createSlice } from '@reduxjs/toolkit';
import { Issue } from '../../api/domain/issues/issues.types';

export const issuesSlice = createSlice({
    name: 'issues',
    initialState: {
        issues: [] as Issue[],
    },
    reducers: {
        addIssues: (state, action) => {
            state.issues = action.payload;
        },
        deleteIssues: (state) => {
            state.issues = [];
        }
    },
})

// Action creators are generated for each case reducer function
export const { addIssues, deleteIssues } = issuesSlice.actions

export default issuesSlice.reducer
