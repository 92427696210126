import React, { useEffect, useState } from 'react';
import { Issue } from '../../../services/api/domain/issues/issues.types';
import { ProjectInfoProps } from '../../../services/api/domain/projects/projects.types';
import InvoiceRoleFee from '../invoiceRoleFee';

import * as S from './InvoiceProjectFeesSc';

type roleInvoiceProps = {
    name: string;
    workload: number;
    workweek: string;
};

type invoiceFeeArr = {
    frontend: Array<roleInvoiceProps>,
    backend: Array<roleInvoiceProps>,
    business: Array<roleInvoiceProps>,
    architect: Array<roleInvoiceProps>,
    devops: Array<roleInvoiceProps>,
};

type invoiceProjectProps = {
    issues: Array<Issue>,
    projectDetail: ProjectInfoProps,
}

const InvoiceProjectFees: React.FC<invoiceProjectProps> = (invoiceProject) => {
    const [invoiceRolesArr, setInvoiceRolesArr] = useState<invoiceFeeArr>();

    const getInvoiceWorkload = (workload:string, employees:string, workweek: string) => {
        let userPensum = 0;
        let counter = 0;
        let userArr = [];
        if(workload !== 'null') {
            let empArr = JSON.parse(employees);
            for(let em = 0; em < empArr.length; em++) {
                if(!workload.includes("h")){
                    if (workload.indexOf(',') > -1) {
                        userPensum = parseFloat(workload.split(',')[em]);
                    } else {
                        if (workload.split(' ').length > 1) {
                            userPensum = parseFloat(workload.split(' ')[counter]);
                            workload.split(' ').length === counter ? counter = 0 : counter++;
                        } else {
                            userPensum = parseFloat(workload);
                        }
                    }
                } else {
                    if(workload.includes("+")){
                        let workloadPlus = workload.split('+');
                        for (let em = 0; em < workloadPlus.length; em++) {
                            if (workloadPlus[em].includes('h')) {
                                userPensum += (100 / 40) * parseFloat(workloadPlus[em].replace('h', ''));
                            } else {
                                userPensum += parseFloat(workloadPlus[em]);
                            }
                        }
                    } else {
                        userPensum = ((100 / 40) * parseFloat(workload.replace("h", "")));
                    }
                }

                userArr.push({
                    name: empArr[em].name,
                    workload: userPensum,
                    workweek: workweek
                });
            }
        }

        return userArr;
    }

    const getInvoiceRolesArr = () => {
        let invoiceArr = {} as invoiceFeeArr;
        invoiceArr.frontend = [] as roleInvoiceProps[];
        invoiceArr.backend = [] as roleInvoiceProps[];
        invoiceArr.business = [] as roleInvoiceProps[];
        invoiceArr.architect = [] as roleInvoiceProps[];
        invoiceArr.devops = [] as roleInvoiceProps[];

        for (const i in invoiceProject.issues) {
            invoiceArr.frontend.push(...getInvoiceWorkload(invoiceProject.issues[i].frontend_workload, invoiceProject.issues[i].frontend_employees, invoiceProject.issues[i].workweek));
            invoiceArr.backend.push(...getInvoiceWorkload(invoiceProject.issues[i].backend_workload, invoiceProject.issues[i].backend_employees, invoiceProject.issues[i].workweek));
            invoiceArr.business.push(...getInvoiceWorkload(invoiceProject.issues[i].business_workload, invoiceProject.issues[i].business_employees, invoiceProject.issues[i].workweek));
            invoiceArr.architect.push(...getInvoiceWorkload(invoiceProject.issues[i].architect_workload, invoiceProject.issues[i].architect_employees, invoiceProject.issues[i].workweek));
            invoiceArr.devops.push(...getInvoiceWorkload(invoiceProject.issues[i].devops_workload, invoiceProject.issues[i].devops_employees, invoiceProject.issues[i].workweek));
        }

        setInvoiceRolesArr(invoiceArr);
    };

    useEffect(() => {
        getInvoiceRolesArr();
    }, [invoiceProject.issues]);

    return (
        <>
            {
                invoiceRolesArr ? (
                    <S.ProjectFeesContainer>
                        <InvoiceRoleFee data={invoiceRolesArr.frontend} title={'Frontend Developer'} projectRoleFee={invoiceProject.projectDetail.frontend_fee} />
                        <InvoiceRoleFee data={invoiceRolesArr.backend} title={'Backend Developer'} projectRoleFee={invoiceProject.projectDetail.backend_fee} />
                        <InvoiceRoleFee data={invoiceRolesArr.architect} title={'Architect Consultant'} projectRoleFee={invoiceProject.projectDetail.architect_fee} />
                        <InvoiceRoleFee data={invoiceRolesArr.business} title={'Business Consultant'} projectRoleFee={invoiceProject.projectDetail.business_fee} />
                        <InvoiceRoleFee data={invoiceRolesArr.devops} title={'DevOps Consultant'} projectRoleFee={invoiceProject.projectDetail.devops_fee} />
                    </S.ProjectFeesContainer>
                ) : (
                    <>
                        <p>No Invoice fee</p>
                    </>
                )
            }
        </>
    );
};

export default InvoiceProjectFees;
