import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api_getIssues, api_initRessourceIssues } from '../../../services/api/domain/issues/issues.queries';
import { addIssues } from '../../../services/store/issues/issues.reducer';
import { changeLastUpdated } from '../../../services/store/lastUpdated/lastUpdated.reducer';
import { RootState } from '../../../services/store/rootReducer';
import LoadingScreen from '../../global/loadingScreen';
import RessourcePlanning from '../../ui/ressourcePlanning';

const Workloads: React.FC = () => {    
    const { issues } = useSelector((state: RootState) => state.issues);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const date = new Date();

    const handleInitIssues = async () => {
        const data = await api_initRessourceIssues();

        dispatch(addIssues(data));
        dispatch(changeLastUpdated(date));
        setIsLoading(false);
    };

    const handleGetIssues = async () => {
        const res = await api_getIssues();

        if (res.length === 0) {
            handleInitIssues();
        } else {
            dispatch(addIssues(res));
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (issues.length === 0) {
            setIsLoading(true);
            handleGetIssues();
        }
    }, [issues]);

    if (issues.length === 0 || isLoading) {
        return <LoadingScreen />
    }

    return (
        <RessourcePlanning />
    );
};

export default Workloads;
