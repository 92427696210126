import httpCommon from '../httpCommon';

import {
    Project, ProjectInfoProps, ProjectInfoReduceProps,
} from './projects.types';

export const api_getProjects = async (): Promise<Project[]> => {
    const {data} = await httpCommon.get(`/projects`);

    return data;
};

export const api_initProjects = async () => {
    const {data} = await httpCommon.get(`/projects/init`);

    return data;
}

export const api_deleteProjects = async () => {
    const {data} = await httpCommon.delete(`/projects/all`);

    return data;
};

export const api_getProjectInfos = async (projectId: string): Promise<ProjectInfoProps> => {
    const {data} = await httpCommon.get(`/projects/project/info/${projectId}`);

    return data;
}

export const api_updateProjectInfos = async (projectId: string, projectData: ProjectInfoReduceProps) => {
    const {data} = await httpCommon.put(`/projects/project/info/update/${projectId}`, projectData);

    return data;
}