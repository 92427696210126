import React from 'react';

import { Navigate, Route, Routes } from 'react-router';
import Invoices from '../../components/pages/invoices';
import ProjectInvoice from '../../components/pages/projectInvoice';
import Workloads from '../../components/pages/workloads';
import WorkloadsEmployee from '../../components/pages/workloadsEmployee';
import WorkloadsProject from '../../components/pages/workloadsProject';
import Page from '../../components/ui/page';

import routes from '../routing/routes';
import routeConstants from '../routing/routes.constants';

const {
    invoices,
    workloads
} = routes;

const Router: React.FC = () => {
  return (
    <Routes>
        <Route element={<Page />}>
            <Route path={`/${routeConstants.base}`}>
                {/* HOME NAVIGATE TO INVOICES */}
                <Route index element={<Navigate
                  to={invoices.segment}
                />} />

                {/* Invoices */}
                <Route path={invoices.segment}>
                  <Route index element={<Invoices />} />
                  <Route path={invoices.project.segment} element={<ProjectInvoice />} />
                </Route>

                {/* Workloads */}
                <Route path={workloads.segment}>
                  <Route index element={<Workloads />} />
                  <Route path={workloads.employee.segment} element={<WorkloadsEmployee />} />
                  <Route path={workloads.project.segment} element={<WorkloadsProject />} />
                </Route>
            </Route>
        </Route>
    </Routes>
  );
};

export default Router;