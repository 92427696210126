import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Issue } from '../../../services/api/domain/issues/issues.types';

import * as S from './IssueTableSc';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ColumnType } from 'antd/lib/table';
import { createPdf } from '../pdfPage/PdfGenerator';
import { calendarWeekProps, getCalendarWeek22, getCalendarWeek23, getCalendarWeek24, getCalendarWeek, getCalendarWeek21 } from '../../../services/api/calendarWeek/getCalenderWeek';
import { ProjectInfoProps } from '../../../services/api/domain/projects/projects.types';

type issueTableProps = {
    id: number;
    name: string;
    workload: string;
    role: string;
    issue_key: string;
    parent_summary: string;
    status: string;
    workweek: string;
    billingfee: string;
};

type issueProps = {
    id: number;
    issue_key: string;
    parent_summary: string;
    status: string;
    workweek: string;
};

type employeeProps = {
    name: string;
    avatar: string;
};

type invoiceProjectProps = {
    issues: Array<Issue>,
    projectDetail: ProjectInfoProps,
}

const IssueTable: React.FC<invoiceProjectProps> = (invoiceProject) => {
    const [data, setData] = useState<issueTableProps[]>([]);
    const [finalData, setFinalData] = useState<issueTableProps[]>([]);
    const [finalMonth, setFinalMonth] = useState('');
    const [cw, setCw] = useState<calendarWeekProps>({
        january: [''],
        february: [''],
        march: [''],
        april: [''],
        may: [''],
        june: [''],
        july: [''],
        august: [''],
        september: [''],
        october: [''],
        november: [''],
        december: [''],
    });
    const [cwLY, setCwLY] = useState<calendarWeekProps>({
        january: [''],
        february: [''],
        march: [''],
        april: [''],
        may: [''],
        june: [''],
        july: [''],
        august: [''],
        september: [''],
        october: [''],
        november: [''],
        december: [''],
    });
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const date = new Date();
    
    const columns: ColumnType<issueTableProps>[] = [
        {
            title: 'Issue',
            dataIndex: 'issue_key',
            key: 'issue_key',
        },
        {
            title: 'State',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                  text: 'Confirmed Work',
                  value: 'Confirmed Work',
                },
                {
                  text: 'Invoiced Work',
                  value: 'Invoiced Work',
                },
              ],
            onFilter: (value, record) => record.status === value,
            defaultFilteredValue: ['Confirmed Work']
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            filters: [
                {
                  text: 'Frontend Developer',
                  value: 'Frontend Developer',
                },
                {
                  text: 'Backend Developer',
                  value: 'Backend Developer',
                },
                {
                  text: 'Architect consultants',
                  value: 'Architect consultants',
                },
                {
                  text: 'Business consultants',
                  value: 'Business consultants',
                },
                {
                  text: 'DevOps consultants',
                  value: 'DevOps consultants',
                },
              ],
            onFilter: (value, record) => record.role === value,
        },
        {
            title: 'Calendarweek',
            dataIndex: 'workweek',
            key: 'workweek',
            filters: [
                {
                    text: date.getFullYear() - 1,
                    value: date.getFullYear() - 1,
                    children: [
                        ...Object.keys(cwLY).map((itemLY: string) => ({
                            text: itemLY + ' ' + (date.getFullYear() - 1),
                            value: itemLY + ' ' + (date.getFullYear() - 1),
                            children: [
                                ...cwLY[itemLY as keyof calendarWeekProps].map((cwLYArr: string) => ({
                                    text: cwLYArr,
                                    value: cwLYArr,
                                })),
                            ],
                        }))
                    ]
                },
                {
                    text: date.getFullYear(),
                    value: date.getFullYear(),
                    children: [
                        ...Object.keys(cw).map((item: string) => ({
                            text: item + ' ' + date.getFullYear(),
                            value: item + ' ' + date.getFullYear(),
                            children: [
                                ...cw[item as keyof calendarWeekProps].map((cwArr: string) => ({
                                    text: cwArr,
                                    value: cwArr,
                                })),
                            ],
                        }))
                    ]
                }
            ],
            filterMode: 'tree',
            onFilter: (value, record) => record.workweek === value,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Workload',
            dataIndex: 'workload',
            key: 'workload',
        },
        {
            title: 'Costs',
            dataIndex: 'billingfee',
            key: 'billingfee',
        },
    ];

    function getBillingFee(userPensum:string, typeFee:number){
        let newUserPensum = parseFloat(userPensum);
        if(typeFee && typeFee.toString() !== "null" && typeFee.toString() !== "0" && typeFee.toString() !== "0.0" && !isNaN(newUserPensum)){
            return ((40 / 100) * newUserPensum) * typeFee + " CHF"
        } else {
            return ((40 / 100) * newUserPensum) * 180 + " CHF"
        }
    }

    function getDetailIssueArr(workload:string, employees:string, issue:issueProps, type:string, typeFee:number) {
        let projectArr: issueTableProps[] = [];
        if(employees !== "[\"null\"]"){
            let empArr: employeeProps[] = JSON.parse(employees);
            for(const e in empArr) {
                let userPensum = workload;
                if (userPensum !== 'null') {
                    if(!userPensum.includes("h")){
                        if (workload.indexOf(',') > -1) {
                            userPensum = workload.split(',')[e];
                        }
                    }
                }
                userPensum = userPensum === 'null' ? "0%" : userPensum;

                projectArr.push({
                    id: issue.id,
                    name: empArr[e].name,
                    workload: userPensum,
                    role: type,
                    issue_key: issue.issue_key,
                    parent_summary: issue.parent_summary,
                    status: issue.status,
                    workweek: issue.workweek,
                    billingfee: getBillingFee(userPensum, typeFee)
                });
            }
        }

        return projectArr;
    }

    function getTableData() {
        let invoiceWorkArr: issueTableProps[] = [];
        for(const i in invoiceProject.issues) {
            invoiceWorkArr.push(...getDetailIssueArr(invoiceProject.issues[i].frontend_workload, invoiceProject.issues[i].frontend_employees, invoiceProject.issues[i], 'Frontend Developer', invoiceProject.projectDetail.frontend_fee));
            invoiceWorkArr.push(...getDetailIssueArr(invoiceProject.issues[i].backend_workload, invoiceProject.issues[i].backend_employees, invoiceProject.issues[i], 'Backend Developer', invoiceProject.projectDetail.backend_fee));
            invoiceWorkArr.push(...getDetailIssueArr(invoiceProject.issues[i].business_workload, invoiceProject.issues[i].business_employees, invoiceProject.issues[i], 'Business consultants', invoiceProject.projectDetail.business_fee));
            invoiceWorkArr.push(...getDetailIssueArr(invoiceProject.issues[i].architect_workload, invoiceProject.issues[i].architect_employees, invoiceProject.issues[i], 'Architect consultants', invoiceProject.projectDetail.architect_fee));
            invoiceWorkArr.push(...getDetailIssueArr(invoiceProject.issues[i].devops_workload, invoiceProject.issues[i].devops_employees, invoiceProject.issues[i], 'DevOps consultants', invoiceProject.projectDetail.devops_fee));
        }

        setData(invoiceWorkArr);
        setFinalData(invoiceWorkArr);
    }

    function countAllWorkloads(rows: issueTableProps[]){
        let totalWorklaod = 0;
        for(const i in rows) {
            let workload = parseFloat(rows[i].workload);
            if(!isNaN(workload) && rows[i].workload !== "null") {
                totalWorklaod += workload;
            } else {
                totalWorklaod += 0;
            }
        }
        return totalWorklaod;
    }

    function countAllWorkloadFees(rows: issueTableProps[]){
        let totalWorklaodFee = 0;
        for(const i in rows) {
            let billFee = parseFloat(rows[i].billingfee.replace(".-", ""))
            totalWorklaodFee += billFee;
        }

        return totalWorklaodFee;
    }

    const exportToCSV = (rows: issueTableProps[]) => {
        let finalRows = [];
        for(const i in rows) {
            finalRows.push(rows[i]);
        }
        finalRows.push({'Total Workload': countAllWorkloads(rows), 'Total Costs': countAllWorkloadFees(rows)});
        const ws = XLSX.utils.json_to_sheet(finalRows);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data1 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data1, 'Invoice-Workload' + `${fileExtension}`);
    };

    const exportToPDF = () => {
        if (finalMonth !== '') {
            createPdf(finalData, finalMonth, invoiceProject.projectDetail, getCalendarWeek());
        }
    };

    useEffect(() => {
        getTableData();

        if (date.getFullYear() === 2022) {
            setCwLY(getCalendarWeek21());
            setCw(getCalendarWeek22());
        }
        if (date.getFullYear() === 2023) {
            setCwLY(getCalendarWeek22());
            setCw(getCalendarWeek23());
        }
        if (date.getFullYear() === 2024) {
            setCwLY(getCalendarWeek23());
            setCw(getCalendarWeek24());
        }
    }, [invoiceProject.issues]);

    return (
        <>
            <S.InvoiceBtnContainer>
                <S.invoiceBtn bgColor='#1D6F42' onClick={() => exportToCSV(finalData)}>Export Excel-File</S.invoiceBtn>
                <S.invoiceBtn 
                    bgColor='#f40f02' 
                    disableBtn={finalMonth === ''} 
                    onClick={() => exportToPDF()}
                    isPdf={true}
                >
                    Export PDF
                </S.invoiceBtn>
            </S.InvoiceBtnContainer>
            <Table 
                columns={columns} 
                dataSource={data} 
                onChange={
                    (pagination, filters, sorter, currentPageData) => {
                        setFinalData(currentPageData.currentDataSource);

                        if (filters.workweek !== null) {
                            for (const i in filters.workweek) {
                                if (!filters.workweek[i].toString().includes('KW')) {
                                    setFinalMonth(filters.workweek[i].toString());
                                    return;
                                }

                                setFinalMonth('');
                            }
                        }
                        setFinalMonth('');
                    }}
            />
        </>
    );
};

export default IssueTable;
