import tw, { css, styled } from 'twin.macro';

export const Container = styled.div(() => [
    css`
        ${tw`flex items-center h-full w-full justify-center p-20 flex-col`};

        & img {
            ${tw`h-20 w-auto mb-20`};
        }
    `
]);

