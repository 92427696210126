import { Spin } from 'antd';
import React from 'react';

import * as S from './LoadingScreenSc';

import { LoadingOutlined } from '@ant-design/icons';

const LoadingScreen: React.FC = () => {
  return (
    <S.Container>
      <img src={require('../../../assets/logos/logo_mit_text.png')} alt="Phoenix Systems AG Logo" />

      <S.LoadingText>Loading Data</S.LoadingText>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
    </S.Container>
  );
}

export default LoadingScreen;
