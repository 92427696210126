import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { WorkweekArr } from '../../../services/api/calendarWeek/getCalenderWeek';
import { WorkloadProps } from '../../../services/api/domain/issues/issues.types';
import { RootState } from '../../../services/store/rootReducer';
import ProjectsPlanningProgress from '../../ui/projectsPlanningProgress';

import * as S from './WorkloadsProjectsSc';

const ProjectInvoice: React.FC = () => {
    const { issues } = useSelector((state: RootState) => state.issues);
    const [selProject, setSelProject] = useState('');
    const [projectName, setProjectName] = useState('');
    const [currentWeek, setCurrentWeek] = useState(0);
    const [relevantWeeks, setRelevantWeeks] = useState<string[]>([]);
    const [detailWeekWorkload, setDetailWeekWorkload] = useState<WorkweekArr>({});
    const params = useParams();
    const date = new Date();

    const getCurrentWeek = () => {
        const oneJan = new Date(date.getFullYear(),0,1);
        const numberOfDays = Math.floor((date.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000));
        setCurrentWeek(Math.ceil(( date.getDay() + 1 + numberOfDays) / 7));
    };

    const returnStringCW = (cw: number) => {
        if(cw < 10){
            return '0' + cw;
        } else {
            if(cw > 52){
                return '0' + (cw - 52);
            } else {
                return cw.toString();
            }
        }
    }
    
    const returnCorrectYear = (cw: number) => {
        if(cw > 52){
            return date.getFullYear() + 1;
        } else {
            return date.getFullYear();
        }
    }

    const getAllCalendarWeeks = () =>{
        let calendarWeeksArr = [];
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek) + ' ' + returnCorrectYear(currentWeek));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 1) + ' ' + returnCorrectYear(currentWeek + 1));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 2) + ' ' + returnCorrectYear(currentWeek + 2));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 3) + ' ' + returnCorrectYear(currentWeek + 3));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 4) + ' ' + returnCorrectYear(currentWeek + 4));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 5) + ' ' + returnCorrectYear(currentWeek + 5));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 6) + ' ' + returnCorrectYear(currentWeek + 6));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 7) + ' ' + returnCorrectYear(currentWeek + 7));

        setRelevantWeeks(calendarWeeksArr);
    }

    function getWorkweekDetailWorkload(workload:string, employees:string, role: string) {
        let userPensum = 0;
        let userArr: WorkloadProps[] = [];
        if(employees !== "[\"null\"]"){
            let empArr = JSON.parse(employees);
            for(let e = 0; e < empArr.length; e++) {
                if (workload !== 'null') {
                    if (workload.indexOf(',') > -1) {
                        userPensum = parseFloat(workload.split(',')[e].replace("%", ""));
                    } else {
                        userPensum = parseFloat(workload.replace("%", ""));
                    }
                } else {
                    userPensum = 0;
                }

                userArr.push({
                    name: empArr[e].name,
                    workload: userPensum,
                    role
                });
            }
        }

        return userArr;
    }

    const getWorkweekWorkloads = () => {
        let projectName = '';
        let workweekDetailArr = {} as WorkweekArr;

        for(const w in relevantWeeks) {
            let allRoleArr = [] as WorkloadProps[];
            let mainWeek: string = relevantWeeks[w].slice(0, 5).replace('-', '');

            for(const i in issues) {
                if(issues[i].workweek === relevantWeeks[w] && issues[i].parent_id === selProject) {
                    projectName = issues[i].parent_summary;
                    allRoleArr.push(...getWorkweekDetailWorkload(issues[i].frontend_workload, issues[i].frontend_employees, 'Frontend Developer'));
                    allRoleArr.push(...getWorkweekDetailWorkload(issues[i].backend_workload, issues[i].backend_employees, 'Backend Developer'));
                    allRoleArr.push(...getWorkweekDetailWorkload(issues[i].devops_workload, issues[i].devops_employees, 'DevOps consultant'));
                    allRoleArr.push(...getWorkweekDetailWorkload(issues[i].business_workload, issues[i].business_employees, 'Business consultant'));
                    allRoleArr.push(...getWorkweekDetailWorkload(issues[i].architect_workload, issues[i].architect_employees, 'Architect consultant'));
                }
            }

            workweekDetailArr[mainWeek as keyof WorkweekArr] = allRoleArr;
        }

        setProjectName(projectName);
        setDetailWeekWorkload(workweekDetailArr);
    };

    useEffect(() => {
        if (relevantWeeks.length > 0 && Object.keys(detailWeekWorkload).length === 0 && selProject !== '') {
            getWorkweekWorkloads();
        }
    }, [relevantWeeks, selProject, detailWeekWorkload]);

    useEffect(() => {
        if (currentWeek !== 0) {
            getAllCalendarWeeks();
        }
    }, [currentWeek]);

    useEffect(() => {
        getCurrentWeek();
    });

    useEffect(() => {
        if (params?.projId && params?.projId !== undefined) {
            setSelProject(params.projId);
            setDetailWeekWorkload({});
            setProjectName('');
        }
    }, [params]);
    
    return (
        <>
            <S.WorkloadProjectsTitleContainer>
                <S.WorkloadProjectsTitle>{projectName}</S.WorkloadProjectsTitle>
            </S.WorkloadProjectsTitleContainer>

            <S.WorkloadProjectsContainer>
            {
                detailWeekWorkload && Object.keys(detailWeekWorkload).length > 0 && (
                    Object.keys(detailWeekWorkload).map((week, index) => {
                        return (
                            <ProjectsPlanningProgress 
                                weekData={detailWeekWorkload[Object.keys(detailWeekWorkload)[index] as keyof WorkweekArr]}
                                week={week} 
                            />
                        );
                    })
                )
            }
            </S.WorkloadProjectsContainer>
        </>
    );
};

export default ProjectInvoice;
