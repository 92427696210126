import tw, { css, styled } from 'twin.macro';

export const WorkloadProjectsTitleContainer = styled.div(() => [
    css`
        ${tw`mb-20`}
    `
]);

export const WorkloadProjectsTitle = styled.h1(() => [
    css`
        ${tw`text-4xl font-bold text-gray-900`}
    `
]);

export const WorkloadProjectsContainer = styled.div(() => [
    css`
        ${tw`flex flex-row overflow-auto w-full`}
        min-width: 900px;
        max-width: 1600px;
    `
]);
