import tw, { css, styled } from 'twin.macro';

export const EmployeeDetails = styled.div(() => [
    css`
        ${tw`flex flex-row mb-20`}
    `
]);
export const EmployeeImg = styled.img(() => [
    css`
        width: 60px;
        height: 60px;
    `
]);
export const EmployeeInfoContainer = styled.div(() => [
    css`
        ${tw`flex flex-col justify-center ml-5`}
    `
]);

export const EmployeeProgressContainer = styled.div(() => [
    css`
        ${tw`flex lg:flex-row flex-col w-full justify-between mb-20 overflow-auto`}

        min-width: 900px;
        max-width: 1300px;
    `
]);
