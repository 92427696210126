import tw, { css, styled } from 'twin.macro';

export const Container = styled.div(() => [
    css`
        ${tw`flex items-center w-full justify-center p-10 flex-col`};

        height: 100px;

        & img {
            ${tw`h-20 w-auto mb-20`};
        }
    `
]);

export const LoadingText = styled.h1(() => [
    css`
        ${tw`text-center text-3xl mb-10`};
    `
]);
