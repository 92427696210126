import { WorkloadProps } from "../domain/issues/issues.types";

export type calendarWeekProps = {
    january: string[],
    february: string[],
    march: string[],
    april: string[],
    may: string[],
    june: string[],
    july: string[],
    august: string[],
    september: string[],
    october: string[],
    november: string[],
    december: string[],
};

export type WorkweekArr = {
    KW01?: WorkloadProps[],
    KW02?: WorkloadProps[],
    KW03?: WorkloadProps[],
    KW04?: WorkloadProps[],
    KW05?: WorkloadProps[],
    KW06?: WorkloadProps[],
    KW07?: WorkloadProps[],
    KW08?: WorkloadProps[],
    KW09?: WorkloadProps[],
    KW10?: WorkloadProps[],
    KW11?: WorkloadProps[],
    KW12?: WorkloadProps[],
    KW13?: WorkloadProps[],
    KW14?: WorkloadProps[],
    KW15?: WorkloadProps[],
    KW16?: WorkloadProps[],
    KW17?: WorkloadProps[],
    KW18?: WorkloadProps[],
    KW19?: WorkloadProps[],
    KW20?: WorkloadProps[],
    KW21?: WorkloadProps[],
    KW22?: WorkloadProps[],
    KW23?: WorkloadProps[],
    KW24?: WorkloadProps[],
    KW25?: WorkloadProps[],
    KW26?: WorkloadProps[],
    KW27?: WorkloadProps[],
    KW28?: WorkloadProps[],
    KW29?: WorkloadProps[],
    KW30?: WorkloadProps[],
    KW31?: WorkloadProps[],
    KW32?: WorkloadProps[],
    KW33?: WorkloadProps[],
    KW34?: WorkloadProps[],
    KW35?: WorkloadProps[],
    KW36?: WorkloadProps[],
    KW37?: WorkloadProps[],
    KW38?: WorkloadProps[],
    KW39?: WorkloadProps[],
    KW40?: WorkloadProps[],
    KW41?: WorkloadProps[],
    KW42?: WorkloadProps[],
    KW43?: WorkloadProps[],
    KW44?: WorkloadProps[],
    KW45?: WorkloadProps[],
    KW46?: WorkloadProps[],
    KW47?: WorkloadProps[],
    KW48?: WorkloadProps[],
    KW49?: WorkloadProps[],
    KW50?: WorkloadProps[],
    KW51?: WorkloadProps[],
    KW52?: WorkloadProps[],
};

export type WorkweekArrProps = {
    [key: string]: string[];
};

export const MONTHS = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'] as const;
export type MonthItems = typeof MONTHS[number];

export const getCalendarWeek = () => {
    return [
            'KW-01',
            'KW-02',
            'KW-03',
            'KW-04',
            'KW-05',
            'KW-06',
            'KW-07',
            'KW-08',
            'KW-09',
            'KW-10',
            'KW-11',
            'KW-12',
            'KW-13',
            'KW-14',
            'KW-15',
            'KW-16',
            'KW-17',
            'KW-18',
            'KW-19',
            'KW-20',
            'KW-21',
            'KW-22',
            'KW-23',
            'KW-24',
            'KW-25',
            'KW-26',
            'KW-27',
            'KW-28',
            'KW-29',
            'KW-30',
            'KW-31',
            'KW-32',
            'KW-33',
            'KW-34',
            'KW-35',
            'KW-36',
            'KW-37',
            'KW-38',
            'KW-39',
            'KW-40',
            'KW-41',
            'KW-42',
            'KW-43',
            'KW-44',
            'KW-45',
            'KW-46',
            'KW-47',
            'KW-48',
            'KW-49',
            'KW-50',
            'KW-51',
            'KW-52',
    ]
}

export const getCalendarWeek21 = () => {
    return {
        january: [
            'KW-01 2021',
            'KW-02 2021',
            'KW-03 2021',
            'KW-04 2021',
        ],
        february: [
            'KW-05 2021',
            'KW-06 2021',
            'KW-07 2021',
            'KW-08 2021',
        ],
        march: [
            'KW-09 2021',
            'KW-10 2021',
            'KW-11 2021',
            'KW-12 2021',
            'KW-13 2021',
        ],
        april: [
            'KW-14 2021',
            'KW-15 2021',
            'KW-16 2021',
            'KW-17 2021',
        ],
        may: [
            'KW-18 2021',
            'KW-19 2021',
            'KW-20 2021',
            'KW-21 2021',
            'KW-22 2021',
        ],
        june: [
            'KW-23 2021',
            'KW-24 2021',
            'KW-25 2021',
            'KW-26 2021',
        ],
        july: [
            'KW-27 2021',
            'KW-28 2021',
            'KW-29 2021',
            'KW-30 2021',
        ],
        august: [
            'KW-31 2021',
            'KW-32 2021',
            'KW-33 2021',
            'KW-34 2021',
            'KW-35 2021',
        ],
        september: [
            'KW-36 2021',
            'KW-37 2021',
            'KW-38 2021',
            'KW-39 2021',
        ],
        october: [
            'KW-40 2021',
            'KW-41 2021',
            'KW-42 2021',
            'KW-43 2021',
        ],
        november: [
            'KW-44 2021',
            'KW-45 2021',
            'KW-46 2021',
            'KW-47 2021',
            'KW-48 2021',
        ],
        december: [
            'KW-49 2021',
            'KW-50 2021',
            'KW-51 2021',
            'KW-52 2021',
        ]
    } as calendarWeekProps;
};

export const getCalendarWeek22 = () => {
    return {
        january: [
            'KW-01 2022',
            'KW-02 2022',
            'KW-03 2022',
            'KW-04 2022',
        ],
        february: [
            'KW-05 2022',
            'KW-06 2022',
            'KW-07 2022',
            'KW-08 2022',
            'KW-09 2022',
        ],
        march: [
            'KW-10 2022',
            'KW-11 2022',
            'KW-12 2022',
            'KW-13 2022',
        ],
        april: [
            'KW-14 2022',
            'KW-15 2022',
            'KW-16 2022',
            'KW-17 2022',
        ],
        may: [
            'KW-18 2022',
            'KW-19 2022',
            'KW-20 2022',
            'KW-21 2022',
            'KW-22 2022',
        ],
        june: [
            'KW-23 2022',
            'KW-24 2022',
            'KW-25 2022',
            'KW-26 2022',
        ],
        july: [
            'KW-27 2022',
            'KW-28 2022',
            'KW-29 2022',
            'KW-30 2022',
        ],
        august: [
            'KW-31 2022',
            'KW-32 2022',
            'KW-33 2022',
            'KW-34 2022',
            'KW-35 2022',
        ],
        september: [
            'KW-36 2022',
            'KW-37 2022',
            'KW-38 2022',
            'KW-39 2022',
        ],
        october: [
            'KW-40 2022',
            'KW-41 2022',
            'KW-42 2022',
            'KW-43 2022',
            'KW-44 2022',
        ],
        november: [
            'KW-45 2022',
            'KW-46 2022',
            'KW-47 2022',
            'KW-48 2022',
        ],
        december: [
            'KW-49 2022',
            'KW-50 2022',
            'KW-51 2022',
            'KW-52 2022',
        ]
    } as calendarWeekProps;
};

export const getCalendarWeek23 = () => {
    return {
        january: [
            'KW-01 2022',
            'KW-02 2022',
            'KW-03 2022',
            'KW-04 2022',
            'KW-05 2022',
        ],
        february: [
            'KW-06 2022',
            'KW-07 2022',
            'KW-08 2022',
            'KW-09 2022',
        ],
        march: [
            'KW-10 2022',
            'KW-11 2022',
            'KW-12 2022',
            'KW-13 2022',
        ],
        april: [
            'KW-14 2022',
            'KW-15 2022',
            'KW-16 2022',
            'KW-17 2022',
        ],
        may: [
            'KW-18 2022',
            'KW-19 2022',
            'KW-20 2022',
            'KW-21 2022',
            'KW-22 2022',
        ],
        june: [
            'KW-23 2022',
            'KW-24 2022',
            'KW-25 2022',
            'KW-26 2022',
        ],
        july: [
            'KW-27 2022',
            'KW-28 2022',
            'KW-29 2022',
            'KW-30 2022',
            'KW-31 2022',
        ],
        august: [
            'KW-32 2022',
            'KW-33 2022',
            'KW-34 2022',
            'KW-35 2022',
        ],
        september: [
            'KW-36 2022',
            'KW-37 2022',
            'KW-38 2022',
            'KW-39 2022',
        ],
        october: [
            'KW-40 2022',
            'KW-41 2022',
            'KW-42 2022',
            'KW-43 2022',
            'KW-44 2022',
        ],
        november: [
            'KW-45 2022',
            'KW-46 2022',
            'KW-47 2022',
            'KW-48 2022',
        ],
        december: [
            'KW-49 2022',
            'KW-50 2022',
            'KW-51 2022',
            'KW-52 2022',
        ]
    } as calendarWeekProps;
};

export const getCalendarWeek24 = () => {
    return {
        january: [
            'KW-01 2022',
            'KW-02 2022',
            'KW-03 2022',
            'KW-04 2022',
            'KW-05 2022',
        ],
        february: [
            'KW-06 2022',
            'KW-07 2022',
            'KW-08 2022',
            'KW-09 2022',
        ],
        march: [
            'KW-10 2022',
            'KW-11 2022',
            'KW-12 2022',
            'KW-13 2022',
        ],
        april: [
            'KW-14 2022',
            'KW-15 2022',
            'KW-16 2022',
            'KW-17 2022',
            'KW-18 2022',
        ],
        may: [
            'KW-19 2022',
            'KW-20 2022',
            'KW-21 2022',
            'KW-22 2022',
        ],
        june: [
            'KW-23 2022',
            'KW-24 2022',
            'KW-25 2022',
            'KW-26 2022',
        ],
        july: [
            'KW-27 2022',
            'KW-28 2022',
            'KW-29 2022',
            'KW-30 2022',
            'KW-31 2022',
        ],
        august: [
            'KW-32 2022',
            'KW-33 2022',
            'KW-34 2022',
            'KW-35 2022',
        ],
        september: [
            'KW-36 2022',
            'KW-37 2022',
            'KW-38 2022',
            'KW-39 2022',
            'KW-40 2022',
        ],
        october: [
            'KW-41 2022',
            'KW-42 2022',
            'KW-43 2022',
            'KW-44 2022',
        ],
        november: [
            'KW-45 2022',
            'KW-46 2022',
            'KW-47 2022',
            'KW-48 2022',
        ],
        december: [
            'KW-49 2022',
            'KW-50 2022',
            'KW-51 2022',
            'KW-52 2022',
        ]
    } as calendarWeekProps;
};
