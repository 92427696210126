import React from 'react';

import { QueryClientProvider } from 'react-query';
import queryClient from '../../../services/api/queryClient';
import Router from '../../../services/router';
import AuthProvider from '../authProvider';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
