import { combineReducers } from 'redux';
import issues from './issues/issues.reducer';
import projects from './projects/projects.reducer';
import employees from './employees/employees.reducer';
import lastUpdated from './lastUpdated/lastUpdated.reducer';

const reducers = combineReducers({
    issues: issues,
    projects: projects,
    employees: employees,
    lastUpdated: lastUpdated
});

export type RootState = ReturnType<typeof reducers>

export default reducers;
