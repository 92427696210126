import { createSlice } from '@reduxjs/toolkit';
import { Employee } from '../../api/domain/employees/employee.types';

export const EmployeesSlice = createSlice({
    name: 'employees',
    initialState: {
        employees: [] as Employee[]
    },
    reducers: {
        addEmployees: (state, action) => {
            state.employees = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { addEmployees } = EmployeesSlice.actions

export default EmployeesSlice.reducer
