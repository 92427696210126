import tw, { css, styled } from 'twin.macro';
import { Collapse } from 'antd';

export const InvoiceRoleContainer = styled.div(() => [
    css`
        ${tw`flex flex-col items-center py-10 px-10 bg-white mb-4 mr-4 mt-8`}

        border-radius: 15px;
        min-width: 200px;
    `
]);

export const InvoiceRoleCollapse = styled(Collapse)(() => [
    css`
        ${tw`flex flex-col items-center py-10 px-5 bg-white mb-4 mr-4 mt-8`}

        border-radius: 15px;
        min-width: 200px;
    `
]);

export const InvoiceRoleTitle = styled.p(() => [
    css`
        ${tw`text-2xl font-bold`}
    `
]);

export const PanelTitle = styled.div(() => [
    css`
        ${tw`flex flex-col`}
    `
]);

export const PanelInnerItem = styled.div(() => [
    css`
        ${tw`flex flex-col`}

        & p {
            padding-left: 25px;
        }
    `
]);
