import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { WorkweekArr } from '../../../services/api/calendarWeek/getCalenderWeek';
import { Employee } from '../../../services/api/domain/employees/employee.types';
import { WorkloadProps } from '../../../services/api/domain/issues/issues.types';
import { RootState } from '../../../services/store/rootReducer';
import EmployeeProgress from '../../ui/employeeProgress';
import EmployeeTable from '../../ui/employeeTable';

import * as S from './WorkloadsEmployeeSc';

const WorkloadsEmployee: React.FC = () => {
    const params = useParams();
    const { issues } = useSelector((state: RootState) => state.issues);
    const { employees } = useSelector((state: RootState) => state.employees);
    const [selEmp, setSelEmp] = useState('');
    const [empData, setEmpData] = useState<Employee>();
    const [currentWeek, setCurrentWeek] = useState(0);
    const [relevantWeeks, setRelevantWeeks] = useState<string[]>([]);
    const [detailWeekWorkload, setDetailWeekWorkload] = useState<WorkweekArr>({});
    const date = new Date();

    const getCurrentWeek = () => {
        const oneJan = new Date(date.getFullYear(),0,1);
        const numberOfDays = Math.floor((date.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000));
        setCurrentWeek(Math.ceil(( date.getDay() + 1 + numberOfDays) / 7));
    };

    const returnStringCW = (cw: number) => {
        if(cw < 10){
            return '0' + cw;
        } else {
            if(cw > 52){
                return '0' + (cw - 52);
            } else {
                return cw.toString();
            }
        }
    }
    
    const returnCorrectYear = (cw: number) => {
        if(cw > 52){
            return date.getFullYear() + 1;
        } else {
            return date.getFullYear();
        }
    }

    const getAllCalendarWeeks = () =>{
        let calendarWeeksArr = [];
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek) + ' ' + returnCorrectYear(currentWeek));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 1) + ' ' + returnCorrectYear(currentWeek + 1));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 2) + ' ' + returnCorrectYear(currentWeek + 2));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 3) + ' ' + returnCorrectYear(currentWeek + 3));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 4) + ' ' + returnCorrectYear(currentWeek + 4));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 5) + ' ' + returnCorrectYear(currentWeek + 5));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 6) + ' ' + returnCorrectYear(currentWeek + 6));
        calendarWeeksArr.push('KW-' + returnStringCW(currentWeek + 7) + ' ' + returnCorrectYear(currentWeek + 7));

        setRelevantWeeks(calendarWeeksArr);
    }

    const userExists = (arr: WorkloadProps[], username:string) => {
        return arr.some(function(el) {
          return el.name === username;
        }); 
    }

    function getWorkweekDetailWorkload(workload:string, employees:string, role: string) {
        let userPensum = 0;
        let userArr: WorkloadProps[] = [];
        if(employees !== "[\"null\"]"){
            let empArr = JSON.parse(employees);
            for(let e = 0; e < empArr.length; e++) {
                if (workload !== 'null') {
                    if (workload.indexOf(',') > -1) {
                        userPensum = parseFloat(workload.split(',')[e].replace("%", ""));
                    } else {
                        userPensum = parseFloat(workload.replace("%", ""));
                    }
                } else {
                    userPensum = 0;
                }

                userArr.push({
                    name: empArr[e].name,
                    workload: userPensum,
                    role
                });
            }
        }

        return userArr;
    }

    const getWorkweekWorkloads = () => {
        let workweekDetailArr = {} as WorkweekArr;

        for(const w in relevantWeeks) {
            let allRoleArr = [] as WorkloadProps[];
            let finalAllRoleArr = [] as WorkloadProps[];
            let mainWeek: string = relevantWeeks[w].slice(0, 5).replace('-', '');

            for(const i in issues) {
                if(issues[i].workweek === relevantWeeks[w]) {
                    allRoleArr.push(...getWorkweekDetailWorkload(issues[i].frontend_workload, issues[i].frontend_employees, 'Frontend Developer'));
                    allRoleArr.push(...getWorkweekDetailWorkload(issues[i].backend_workload, issues[i].backend_employees, 'Backend Developer'));
                    allRoleArr.push(...getWorkweekDetailWorkload(issues[i].devops_workload, issues[i].devops_employees, 'DevOps consultant'));
                    allRoleArr.push(...getWorkweekDetailWorkload(issues[i].business_workload, issues[i].business_employees, 'Business consultant'));
                    allRoleArr.push(...getWorkweekDetailWorkload(issues[i].architect_workload, issues[i].architect_employees, 'Architect consultant'));
                }
            }

            for(const r in allRoleArr) {
                if (finalAllRoleArr.length === 0 || !userExists(finalAllRoleArr, allRoleArr[r].name)) {
                    finalAllRoleArr.push({
                        workload: allRoleArr[r].workload,
                        name: allRoleArr[r].name,
                        role: allRoleArr[r].role,
                    });
                } else {
                    finalAllRoleArr[finalAllRoleArr.findIndex(x => x.name === allRoleArr[r].name)].workload += allRoleArr[r].workload;
                }
            }

            workweekDetailArr[mainWeek as keyof WorkweekArr] = finalAllRoleArr;
        }

        setDetailWeekWorkload(workweekDetailArr);
    };

    useEffect(() => {
        if (relevantWeeks.length > 0 && Object.keys(detailWeekWorkload).length === 0) {
            getWorkweekWorkloads();
        }
    }, [relevantWeeks]);

    useEffect(() => {
        if (currentWeek !== 0) {
            getAllCalendarWeeks();
        }
    }, [currentWeek]);

    useEffect(() => {
        getCurrentWeek();
    });

    useEffect(() => {
        if (params && params.empName !== undefined) {
            setSelEmp(params.empName);
            setEmpData(employees[employees.findIndex(x => x.displayName === params.empName)]);
        }
    }, [params]);
    
    return (
        <>
            {empData && Object.keys(empData).length > 0 ? (
                <S.EmployeeDetails>
                    <S.EmployeeImg src={empData?.avatarUrl} alt="employee" />
                    <S.EmployeeInfoContainer>
                        <p>{empData?.displayName}</p>
                        <p>{empData?.emailAddress}</p>    
                    </S.EmployeeInfoContainer>    
                </S.EmployeeDetails>
            ) : (
                <S.EmployeeDetails>
                    <S.EmployeeImg src={require('../../../assets/images/default-avatar.png')} alt="employee" />
                    <S.EmployeeInfoContainer>
                        <p>{selEmp}</p>
                        <p></p>    
                    </S.EmployeeInfoContainer>    
                </S.EmployeeDetails>
            )}

            <S.EmployeeProgressContainer>
                        {
                            Object.keys(detailWeekWorkload).map((week, index) => {
                                return (
                                    <EmployeeProgress 
                                        key={week + index}
                                        name={selEmp}
                                        weekData={detailWeekWorkload[Object.keys(detailWeekWorkload)[index] as keyof WorkweekArr]} 
                                        week={week} 
                                    />
                                );
                            })
                        }
            </S.EmployeeProgressContainer>

            {params && params.empName && <EmployeeTable />}
        </>
    );
};

export default WorkloadsEmployee;
