import FileSaver from 'file-saver';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { ProjectInfoProps } from '../../../services/api/domain/projects/projects.types';

type issueTableProps = {
  id: number;
  name: string;
  workload: string;
  role: string;
  issue_key: string;
  parent_summary: string;
  status: string;
  workweek: string;
  billingfee: string;
};

type roleInvoiceProps = {
  workload: number;
  name: string;
  workweek: string;
};

type invoiceFeeArr = {
  frontend: Array<roleInvoiceProps>,
  backend: Array<roleInvoiceProps>,
  business: Array<roleInvoiceProps>,
  architect: Array<roleInvoiceProps>,
  devops: Array<roleInvoiceProps>,
};

export const createPdf = async (finalData: issueTableProps[], selectedMonth: string, projectDetail: ProjectInfoProps, calendarweeks: string[]) => {
  const pdfDoc = await PDFDocument.create();
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  const pdfExtension = '.pdf';

  const createFinalPdf = async (roleArr: invoiceFeeArr) => {
    const page = pdfDoc.addPage();

    const pdfDrawText = (title: string, start: number, role: roleInvoiceProps, issueMonth: string, notTotal: number, fee: number, hourCost?: number) => {
      return (
        page.drawText(title, {
          x: 50 + notTotal,
          y: start,
          size: fontSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }),
        page.drawText(issueMonth.charAt(0).toUpperCase() + issueMonth.slice(1), {
          x: 190,
          y: startAt,
          size: fontSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }),
        page.drawText(role.workload.toString() + '%', {
          x: 300,
          y: startAt,
          size: fontSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }),
        page.drawText(
          (hourCost !== undefined ? ((hourCost.toString() === 'null' || hourCost.toString() === '0.0' || hourCost.toString() === '0' ? 180.00 : hourCost) + ' CHF') : ''), {
          x: 360,
          y: startAt,
          size: fontSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }),
        page.drawText((((40 / 100) * role.workload) * (fee.toString() === 'null' || fee.toString() === '0' || fee.toString() === '0.0' ? 180.00 : fee)).toString() + ' CHF', {
          x: 490,
          y: startAt,
          size: fontSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        })
      )
    };

    const pngLogoBytes = await fetch(require('../../../assets/logos/logo_mit_text.png')).then((res) => res.arrayBuffer());
    const logoImage = await pdfDoc.embedPng(pngLogoBytes);

    const fontSize = 10;
    const fontSizeM = 14;
    let startAt = 540;

    page.drawImage(logoImage, {
      x: 50,
      y: 800,
      width: 150,
      height: 15,
    })
    page.drawText('Phoenix Systems AG BU: Enterprise', {
      x: 50,
      y: 780,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawText('Hardturmstrasse 103', {
      x: 50,
      y: 770,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawText('Trakt B', {
      x: 50,
      y: 760,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawText('8005 Zürich', {
      x: 50,
      y: 750,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawText('CHE-115.673.674 MWST', {
      x: 50,
      y: 740,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    page.drawText('Invoice supplement for ' + selectedMonth.charAt(0).toUpperCase() + selectedMonth.slice(1), {
      x: 50,
      y: 600,
      size: fontSizeM,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    page.drawText('Description', {
      x: 50,
      y: 560,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawText('Month', {
      x: 190,
      y: 560,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawText('Workload', {
      x: 300,
      y: 560,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawText('Cost per Hour', {
      x: 360,
      y: 560,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawText('Total Cost', {
      x: 490,
      y: 560,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    Object.keys(roleArr).map((key, index) => {
      if (roleArr[key as keyof typeof roleArr].length > 1) {
        roleArr[key as keyof typeof roleArr].map((role, i) => {
          startAt = startAt - 15;

          if (key === 'frontend') {
            if (role.name === 'total') {
              pdfDrawText('Senior Frontend Developer', startAt, role, selectedMonth, 0, projectDetail.frontend_fee, projectDetail.frontend_fee);
            } else {
              pdfDrawText(role.name, startAt, role, '', 15, projectDetail.frontend_fee);
            }
          } else if (key === 'backend') {
            if (role.name === 'total') {
              pdfDrawText('Senior backend Developer', startAt, role, selectedMonth, 0, projectDetail.backend_fee, projectDetail.backend_fee);
            } else {
              pdfDrawText(role.name, startAt, role, '', 15, projectDetail.backend_fee);
            }
          } else if (key === 'architect') {
            if (role.name === 'total') {
              pdfDrawText('Architect Consultant', startAt, role, selectedMonth, 0, projectDetail.architect_fee, projectDetail.architect_fee);
            } else {
              pdfDrawText(role.name, startAt, role, '', 15, projectDetail.architect_fee);
            }
          }
          else if (key === 'business') {
            if (role.name === 'total') {
              pdfDrawText('Business Consultant', startAt, role, selectedMonth, 0, projectDetail.business_fee, projectDetail.business_fee);
            } else {
              pdfDrawText(role.name, startAt, role, '', 15, projectDetail.business_fee);
            }
          }
          else if (key === 'devops') {
            if (role.name === 'total') {
              pdfDrawText('DevOps Consultant', startAt, role, selectedMonth, 0, projectDetail.devops_fee, projectDetail.devops_fee);
            } else {
              pdfDrawText(role.name, startAt, role, '', 15, projectDetail.devops_fee);
            }
          }
        });
      }
    });

    page.drawText('Subtotal', {
      x: 350,
      y: 320,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawText((calculateTotal(roleArr)).toFixed(2).toString() + ' CHF', {
      x: 490,
      y: 320,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
  
    page.drawText('7.77% MWST', {
      x: 325,
      y: 305,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawText((((calculateTotal(roleArr)) / 100) * 7.7).toFixed(2).toString() + ' CHF', {
      x: 490,
      y: 305,
      size: fontSize,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
  
    page.drawText('Outstanding balance', {
      x: 288,
      y: 290,
      size: fontSize,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    });
    page.drawText(((calculateTotal(roleArr)) + ((calculateTotal(roleArr)) / 100) * 7.7).toFixed(2).toString() + ' CHF', {
      x: 490,
      y: 290,
      size: fontSize,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    });
  

    const pdfFile = await pdfDoc.save();
    const blob = new Blob([pdfFile]);
    FileSaver.saveAs(blob, 'Invoice-Workload-PDF' + `${pdfExtension}`);
  };

  const calculateTotal = (roleArr: invoiceFeeArr) => {
    let total = 0;
    Object.keys(roleArr).map((key) => {
      roleArr[key as keyof typeof roleArr].map((role, i) => {
        if (role.name === 'total' && key === 'frontend') {
          total += (((40 / 100) * role.workload) * (projectDetail.frontend_fee.toString() === 'null' || projectDetail.frontend_fee.toString() === '0' || projectDetail.frontend_fee.toString() === '0.0' ? 180.00 : projectDetail.frontend_fee));
        } else if (role.name === 'total' && key === 'backend') {
          total += (((40 / 100) * role.workload) * (projectDetail.backend_fee.toString() === 'null' || projectDetail.backend_fee.toString() === '0' || projectDetail.backend_fee.toString() === '0.0' ? 180.00 : projectDetail.backend_fee));
        } else if (role.name === 'total' && key === 'architect') {
          total += (((40 / 100) * role.workload) * (projectDetail.architect_fee.toString() === 'null' || projectDetail.architect_fee.toString() === '0' || projectDetail.architect_fee.toString() === '0.0' ? 180.00 : projectDetail.architect_fee));
        } else if (role.name === 'total' && key === 'business') {
          total += (((40 / 100) * role.workload) * (projectDetail.business_fee.toString() === 'null' || projectDetail.business_fee.toString() === '0' || projectDetail.business_fee.toString() === '0.0' ? 180.00 : projectDetail.business_fee));
        } else if (role.name === 'total' && key === 'devops') {
          total += (((40 / 100) * role.workload) * (projectDetail.devops_fee.toString() === 'null' || projectDetail.devops_fee.toString() === '0' || projectDetail.devops_fee.toString() === '0.0' ? 180.00 : projectDetail.devops_fee));
        }
      });
    });

    return total;
  }

  const getInvoiceWorkload = (workload:string) => {
    let userPensum = 0;
    if(workload !== 'null') {
        if(!workload.includes("h")){
            if (workload.indexOf(',') > -1) {
                const workloadArr = workload.split(',');
                for (const i in workloadArr) {
                  userPensum += parseFloat(workloadArr[i]);
                }
            } else {
                if (workload.split(' ').length > 1) {
                    const workloadArr = workload.split(' ');
                    for (const i in workloadArr) {
                      if (workloadArr[i] === '') {
                        userPensum += 0;
                      } else {
                        userPensum += parseFloat(workloadArr[i]);
                      }
                    }
                } else {
                    userPensum = parseFloat(workload);
                }
            }
        } else {
            if(workload.includes("+")){
                let workloadPlus = workload.split('+');
                for (let em = 0; em < workloadPlus.length; em++) {
                    if (workloadPlus[em].includes('h')) {
                        userPensum += (100 / 40) * parseFloat(workloadPlus[em].replace('h', ''));
                    } else {
                        userPensum += parseFloat(workloadPlus[em]);
                    }
                }
            } else {
                userPensum = ((100 / 40) * parseFloat(workload.replace("h", "")));
            }
        }

        return userPensum;
    }

    return 0;
  };

  const userExists = (role: roleInvoiceProps[], username:string) => {
    return role.some(function(el) {
      return el.name === username;
    }); 
  }

  const getInvoiceRoleFees = () => {
      let frontend = 0;
      let backend = 0;
      let business = 0;
      let architect = 0;
      let devops = 0;
      let roleArr = {
        frontend: [],
        backend: [],
        business: [],
        architect: [],
        devops: [],
      } as invoiceFeeArr;

      for (let c = 0; c < calendarweeks.length; c++) {
          for (let i = 0; i < finalData.length; i++) {
              if (finalData[i].workweek.includes(calendarweeks[c])) {
                  if (finalData[i].role === 'Frontend Developer') {
                      frontend += getInvoiceWorkload(finalData[i].workload);
                      if (!userExists(roleArr.frontend, finalData[i].name)) {
                        roleArr.frontend.push({
                            workload: getInvoiceWorkload(finalData[i].workload),
                            name: finalData[i].name,
                            workweek: finalData[i].workweek,
                        });
                      } else {
                        roleArr.frontend[roleArr.frontend.findIndex(x => x.name === finalData[i].name)].workload += getInvoiceWorkload(finalData[i].workload);
                      }
                  } else if (finalData[i].role === 'Backend Developer') {
                      backend += getInvoiceWorkload(finalData[i].workload);
                      if (!userExists(roleArr.backend, finalData[i].name)) {
                        roleArr.backend.push({
                            workload: getInvoiceWorkload(finalData[i].workload),
                            name: finalData[i].name,
                            workweek: finalData[i].workweek,
                        });
                      } else {
                        roleArr.backend[roleArr.backend.findIndex(x => x.name === finalData[i].name)].workload += getInvoiceWorkload(finalData[i].workload);
                      }
                  } else if (finalData[i].role === 'Business consultants') {
                      business += getInvoiceWorkload(finalData[i].workload);
                      if (!userExists(roleArr.business, finalData[i].name)) {
                        roleArr.business.push({
                            workload: getInvoiceWorkload(finalData[i].workload),
                            name: finalData[i].name,
                            workweek: finalData[i].workweek,
                        });
                      } else {
                        roleArr.business[roleArr.business.findIndex(x => x.name === finalData[i].name)].workload += getInvoiceWorkload(finalData[i].workload);
                      }
                  } else if (finalData[i].role === 'Architect consultants') {
                      architect += getInvoiceWorkload(finalData[i].workload);
                      if (!userExists(roleArr.architect, finalData[i].name)) {
                        roleArr.architect.push({
                            workload: getInvoiceWorkload(finalData[i].workload),
                            name: finalData[i].name,
                            workweek: finalData[i].workweek,
                        });
                      } else {
                        roleArr.architect[roleArr.architect.findIndex(x => x.name === finalData[i].name)].workload += getInvoiceWorkload(finalData[i].workload);
                      }
                  } else if (finalData[i].role === 'Devops consultants') {
                      devops += getInvoiceWorkload(finalData[i].workload);
                      if (!userExists(roleArr.devops, finalData[i].name)) {
                        roleArr.devops.push({
                            workload: getInvoiceWorkload(finalData[i].workload),
                            name: finalData[i].name,
                            workweek: finalData[i].workweek,
                        });
                      } else {
                        roleArr.devops[roleArr.devops.findIndex(x => x.name === finalData[i].name)].workload += getInvoiceWorkload(finalData[i].workload);
                      }
                  }
              }
          }
      }

      roleArr.frontend.unshift({
          workload: frontend,
          name: 'total',
          workweek: selectedMonth,
      });
      roleArr.backend.unshift({
          workload: backend,
          name: 'total',
          workweek: selectedMonth,
      });
      roleArr.architect.unshift({
          workload: architect,
          name: 'total',
          workweek: selectedMonth,
      });
      roleArr.business.unshift({
        workload: business,
        name: 'total',
        workweek: selectedMonth,
      });
      roleArr.devops.unshift({
        workload: devops,
        name: 'total',
        workweek: selectedMonth,
      });

      createFinalPdf(roleArr);
  };

  getInvoiceRoleFees();
}
