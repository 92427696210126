import tw, { css, styled } from 'twin.macro';

export const DetailContainer = styled.div(() => [
    css`
        ${tw`flex flex-col w-full`}
        max-width: 1500px;
    `
]);

export const DetailInnerContainer = styled.div(() => [
    css`
        ${tw`flex flex-row mt-0 justify-between w-full`}
        max-width: 1200px;
    `
]);

export const InfoContaier = styled.div(() => [
    css`
        ${tw`p-10 ml-0 mr-10`}
        width: 350px;
        border-radius: 10px;
        background: #fff;

        :last-child {
            ${tw`mr-0`}
        }
    `
]);

export const ManagerInnerContainer = styled.div(() => [
    css`
        ${tw`flex flex-col`}
    `
]);

export const ManagerImg = styled.img(() => [
    css`
        ${tw`my-2`}
        width: 50px;
        height: 50px;
    `
]);

export const MaanagerEmail = styled.a(() => [
    css`
        ${tw`no-underline`}
    `
]);

export const InfoContainerTitleOuter = styled.div(() => [
    css`
        ${tw`flex flex-row items-center`}
    `
]);

export const InfoContainerTitle = styled.h1(() => [
    css`
        ${tw`text-2xl my-10 mr-2`}
        width: 100px;
    `
]);

export const InfoTitle = styled.p(() => [
    css`
        ${tw`text-gray-800`}
    `
]);
