import tw, { css, styled } from 'twin.macro';

export const EmployeeProgressItem = styled.div(() => [
    css`
        ${tw`flex flex-col lg:mb-0 mb-10 lg:mr-8 mr-0 items-center`}

        & p {
            ${tw`mb-5`}
        }

        && .ant-progress-text {
            color: #000 !important;
        }
    `
]);
