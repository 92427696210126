import { createSlice } from '@reduxjs/toolkit';

export const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        projects: []
    },
    reducers: {
        addProjects: (state, action) => {
            state.projects = action.payload;
        },
        deleteProjects: (state) => {
            state.projects = [];
        }
    },
})

// Action creators are generated for each case reducer function
export const { addProjects, deleteProjects } = projectsSlice.actions

export default projectsSlice.reducer
