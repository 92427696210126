import httpCommon from '../httpCommon';

export const api_getIssues = async () => {
    const {data} = await httpCommon.get(`/issues`);

    return data;
};

export const api_getProjectIssues = async (projectId: string) => {
    const {data} = await httpCommon.get(`/issues/${projectId}`);

    return data;
}

export const api_initIssues = async () => {
    const {data} = await httpCommon.get(`/issues/init/invoices`);

    return data;
}

export const api_deleteIssues = async () => {
    const {data} = await httpCommon.delete(`/issues/all`);

    return data;
};

export const api_initRessourceIssues = async () => {
    const {data} = await httpCommon.get(`/issues/init/ressources`);

    return data;
};

export const api_searchProjectIssues = async (projectName: string) => {
    const {data} = await httpCommon.get(`/issues/search/` + projectName);

    return data;
};

export const api_searchProjectIssuesById = async (projectId: string) => {
    const {data} = await httpCommon.get(`/issues/search/` + projectId);

    return data;
};
