import { Spin } from 'antd';
import React from 'react';

import * as S from './SplashScreenSc';

import { LoadingOutlined } from '@ant-design/icons';

const SplashScreen: React.FC = () => {
  return (
    <S.Container>
      <img src={require('../../../assets/logos/logo_mit_text.png')} alt="Phoenix Systems AG Logo" />

      <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
    </S.Container>
  );
}

export default SplashScreen;
