import tw, { css, styled } from 'twin.macro';

export const ProjectPlanningWeekContainer = styled.div(() => [
    css`
        ${tw`flex flex-col items-center`}
        min-width: 200px;

        && h1 {
            ${tw`text-3xl font-bold text-gray-900`}
        }
    `
]);

export const ProjectPlanningWeek = styled.div(() => [
    css`
        ${tw`flex flex-col items-center mb-10`}

        && h3 {
            ${tw`font-bold`}
        }

        && h6 {
            ${tw`mb-5`}
        }

        && .ant-progress-text {
            color: #000 !important;
        }
    `
]);
