import React from 'react';

import * as S from './ErrorAuthScreenSc';

const ErrorAuthScreen: React.FC<string> = (errorTxt) => {
  return (
    <S.Container>
      <img src={require('../../../assets/logos/logo_mit_text.png')} alt="Phoenix Systems AG Logo" />

      <h1>{errorTxt}</h1>
      <S.ReloadBtn onClick={() => window.location.reload()}>Reload Screen</S.ReloadBtn>
    </S.Container>
  );
}

export default ErrorAuthScreen;
