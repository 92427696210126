import { Collapse, Progress } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkloadProps } from '../../../services/api/domain/issues/issues.types';
import routes from '../../../services/routing';
import routeConstants from '../../../services/routing/routes.constants';

import * as S from './RessourcePlanningWeekSc';

type RessourcePlanningWeekProps = {
    week: string;
    weekData: WorkloadProps[] | undefined;
};

const {
    workloads
} = routes;

const RessourcePlanningWeek: React.FC<RessourcePlanningWeekProps> = (data) => {   
    const navigate = useNavigate();
    const { Panel } = Collapse;

    const navigateToPerson = (name: string) => {
        navigate(`/${routeConstants.base}${workloads.segment}/${name}`);
    };

    const getPanelData = (workloadData: WorkloadProps) => {
        return (
            <S.RessourceWeekContainer key={workloadData.name}>
                <p onClick={() => navigateToPerson(workloadData.name)}>{workloadData.name}</p>
                <Progress 
                    percent={workloadData.workload} 
                    format={() => workloadData.workload + '%'} 
                    strokeColor={workloadData.workload > 74 && workloadData.workload < 111 ? '#52c41a' : workloadData.workload > 110 ? '#e9521b' : '#f9b75f'} 
                />
            </S.RessourceWeekContainer>
        );
    };

    const getRoleWorkload = (role: string) => {
        let roleWorkload = 0;
        if (data.weekData) {
            data.weekData.forEach((workloadData: WorkloadProps) => {
                if(workloadData.role === role){
                    roleWorkload += workloadData.workload;
                }
            });
        }
        return roleWorkload;
    };

    return (
        <S.RessourceWeekOuterContainer>
            <Collapse ghost>
                <Panel header={data.week} key={data.week}>
                    <>
                        {
                            data.weekData && data.weekData.map((thisWeekData) => {
                                return getPanelData(thisWeekData);
                            })
                        }
                    </>
                </Panel>
            </Collapse>
            <div className={"weekWorkloadItemInner"}>
                <div className={"weekWorklaodItem"}>
                    <p className={"weekWorkloadType"}>Frontend Developer</p>
                    <Progress percent={getRoleWorkload('Frontend Developer')} format={() => getRoleWorkload('Frontend Developer') + '%'} strokeColor={'#1890ff'} />
                </div>
                <div className={"weekWorklaodItem"}>
                    <p className={"weekWorkloadType"}>Backend Developer</p>
                    <Progress percent={getRoleWorkload('Backend Developer')} format={() => getRoleWorkload('Backend Developer') + '%'} strokeColor={'#1890ff'} />
                </div>
                <div className={"weekWorklaodItem"}>
                    <p className={"weekWorkloadType"}>Business consultant</p>
                    <Progress percent={getRoleWorkload('Business consultant')} format={() => getRoleWorkload('Business consultant') + '%'} strokeColor={'#1890ff'} />
                </div>
                <div className={"weekWorklaodItem"}>
                    <p className={"weekWorkloadType"}>Architecture consultant</p>
                    <Progress percent={getRoleWorkload('Architect consultant')} format={() => getRoleWorkload('Architect consultant') + '%'} strokeColor={'#1890ff'} />
                </div>
                <div className={"weekWorklaodItem"}>
                    <p className={"weekWorkloadType"}>DevOps consultants</p>
                    <Progress percent={getRoleWorkload('DevOps consultant')} format={() => getRoleWorkload('DevOps consultant') + '%'} strokeColor={'#1890ff'} />
                </div>
            </div>

        </S.RessourceWeekOuterContainer>
    );
};

export default RessourcePlanningWeek;
