import React from 'react';

import './components/ui/styles/main.css';
import './components/ui/styles/tailwind.css';

import { render } from 'react-dom';

import App from './components/global/app';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './services/store/store';


render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

//reportWebVitals();
