import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ProjectInfoProps, ProjectInfoReduceProps, ProjectInfoUpdateProps } from '../../../services/api/domain/projects/projects.types';
import { EditOutlined } from '@ant-design/icons';

import * as S from './ModalScreenSc';
import { api_updateProjectInfos } from '../../../services/api/domain/projects/projects.queries';

const ModalScreen: React.FC<ProjectInfoProps> = (projectDetail) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editProject, setEditProject] = useState<ProjectInfoProps>(projectDetail);
    const [editedObj, setEditedObj] = useState<ProjectInfoUpdateProps>({
        "customfield_10090": 0,
        "customfield_10114": 0,
        "customfield_10088": 0,
        "customfield_10087": 0,
        "customfield_10085": 0,
        "customfield_10086": 0,
        "customfield_10074": 0,
        "customfield_10076": '',
        "customfield_10067": '',
        "customfield_10068": ''
    });


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSaveEdit = () => {
    const filteredObj: ProjectInfoReduceProps = {};
    //emptyProjectDetail();
    //const filteredObj = Object.entries(editedObj).reduce((a,[k,v]) => (v ? a[k]=v : a), {});
    //console.log(filteredObj);

    for(const i in editedObj) {
        let fObj = editedObj[i as keyof ProjectInfoUpdateProps];

        if(i === "customfield_10090" && typeof fObj === "string") {
          filteredObj[i] = parseFloat(fObj);
        } else if (i === "customfield_10114" && typeof fObj === "string") {
          filteredObj[i] = parseFloat(fObj);
        } else if (i === "customfield_10088" && typeof fObj === "string"){
          filteredObj[i] = parseFloat(fObj);
        } else if (i === "customfield_10087" && typeof fObj === "string"){
          filteredObj[i] = parseFloat(fObj);
        } else if (i === "customfield_10085" && typeof fObj === "string"){
          filteredObj[i] = parseFloat(fObj);
        } else if (i === "customfield_10086" && typeof fObj === "string"){
          filteredObj[i] = parseFloat(fObj);
        } else if(i === "customfield_10074" && typeof fObj === "string") {
          filteredObj[i] = parseFloat(fObj);
        } else if(i === "customfield_10076" && fObj !== "") {
          filteredObj[i] = fObj.toString();
        } else if(i === "customfield_10067" && fObj !== "") {
          filteredObj[i] = fObj.toString();
        } else if(i === "customfield_10068" && fObj !== "") {
          filteredObj[i] = fObj.toString();
        }
    }

    api_updateProjectInfos(projectDetail.project_key, filteredObj).then((resp) => {
      if(resp === 204) {
            window.location.reload();
        } else {
            console.log('NO 204 RESPONSE');
        }
    })
    .catch((e) => {
        console.log('ERROR');
        console.log(e);
    });

    setIsModalVisible(false);
  };

  const handleCancelEdit = () => {
      setEditProject(projectDetail);
      setIsModalVisible(false);
  };

  const changeProjectDetails = (e: React.FormEvent<HTMLInputElement>) => {
      const { name, value, id } = e.currentTarget;
      setEditProject(editProject => ({
          ...editProject,
          [name]: value
      }));

      setEditedObj(editedObj => ({
          ...editedObj,
          [id]: value
      }));
  };


  useEffect(() => {
    setEditProject(projectDetail);
  }, [projectDetail]);

  return (
    <>
      <S.ModalBtn type="primary" onClick={showModal}>
        Edit 
        <EditOutlined />
      </S.ModalBtn>
      
      {editProject !== undefined ? (
        <Modal title={"Edit Projectdetails for " + projectDetail.projekt_name} visible={isModalVisible} onOk={handleSaveEdit} onCancel={handleCancelEdit}>
              <S.ModalContainer>
                  <S.ModalEditContent>
                      <label>
                          Kontakt Email:
                          <input type="text" value={editProject.contact_email} name={"contact_email"} id={"customfield_10068"} onChange={changeProjectDetails} />
                      </label>
                  </S.ModalEditContent>
                  <S.ModalEditContent>
                      <label>
                          Confluence Space URL:
                          <input type="text" value={editProject.project_conf_space} name={"project_conf_space"} id={"customfield_10076"} onChange={changeProjectDetails} />
                      </label>
                      <label>
                          Contract URL:
                          <input type="text" value={editProject.project_contract_url} name={"project_contract_url"} id={"customfield_10074"} onChange={changeProjectDetails} />
                      </label>
                  </S.ModalEditContent>
                  <S.ModalEditContent>
                      <label>
                          Architect consultant Fee:
                          <input type="number" value={editProject.architect_fee} name={"architect_fee"} id={"customfield_10086"} onChange={changeProjectDetails} />
                      </label>

                      <label>
                          Business consultant Fee:
                          <input type="number" value={editProject.business_fee} name={"business_fee"} id={"customfield_10085"} onChange={changeProjectDetails} />
                      </label>

                      <label>
                          Backend Developer Fee:
                          <input type="number" value={editProject.backend_fee} name={"backend_fee"} id={"customfield_10087"} onChange={changeProjectDetails} />
                      </label>

                      <label>
                          Frontend Developer Fee:
                          <input type="number" value={editProject.frontend_fee} name={"frontend_fee"} id={"customfield_10088"} onChange={changeProjectDetails} />
                      </label>

                      <label>
                          Dev/Ops Fee:
                          <input type="number" value={editProject.devops_fee} name={"devops_fee"} id={"customfield_10114"} onChange={changeProjectDetails} />
                      </label>
                  </S.ModalEditContent>
              </S.ModalContainer>
          </Modal>
        ) : (
          <></>
        )}
    </>
  );
};

export default ModalScreen;
