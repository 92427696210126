import { Layout } from 'antd';
import tw, { css, styled } from 'twin.macro';

export const PageContainer = styled(Layout)(() => [
    css`
        & .topHeader {
            ${tw`flex`}

            background-color: #fff;

            & ul {
                ${tw`flex-grow ml-20`}
                background-color: #fff;

                & li {
                    color: #000;
                }
                & li:hover, li.ant-menu-item-selected {
                    background-color: transparent !important;
                    color: #ed7151;
                }
            }
        }

        & aside {
            & li {
                color: #000;
            }
            & div.ant-menu-submenu-title:hover, li:hover, li.ant-menu-item-selected {
                background-color: transparent !important;
                color: #ed7151;
            }
        }
    `
]);

export const HeaderLogoContainer = styled.div(() => [
    css`
        ${tw`flex items-center h-full`};

        & img {
            ${tw`h-10 w-auto`};
        }
    `
]);

