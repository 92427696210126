import tw, { css, styled } from 'twin.macro';
import { Button } from 'antd';

export const ModalContainer = styled.div(() => [
    css`
        ${tw`flex flex-col`}
    `
]);

export const ModalBtn = styled(Button)(() => [
    css`
        ${tw`flex items-center rounded-xl`}
    `
]);

export const ModalEditContent = styled.div(() => [
    css`
        ${tw`flex flex-col`}

        & label {
            ${tw`flex justify-between mb-1`}
        }

        & input {
            width: 50%;
            border-radius: 5px;
            border-width: 1px;
            padding: 2px 5px;
        }

        & textarea {
            width: 50%;
            border-radius: 5px;
            border-width: 1px;
            padding: 2px 5px;
        }
    `
]);
