import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';

import * as S from './InvoiceRoleFeeSc';
import { getCalendarWeek } from '../../../services/api/calendarWeek/getCalenderWeek';

type roleInvoiceProps = {
    name: string;
    workload: number;
    workweek: string;
};

type invoiceRoleProps = {
    data: Array<roleInvoiceProps>;
    title: string;
    projectRoleFee: number;
};

const InvoiceRoleFee: React.FC<invoiceRoleProps> = (invoices) => {
    const { Panel } = Collapse;
    const [workload, setWorkload] = useState(0);
    const [billingFee, setBillingFee] = useState(0.00);
    const [cwArr, setCwArr] = useState<string[]>();

    function calculateWorkFee() {
        if(invoices.data){
            if (invoices.projectRoleFee === null) {
                setBillingFee(0.00);
                return;
            }

            let workFee = invoices.projectRoleFee;
            let fee = ((40 / 100) * workload) * workFee;
            if (isNaN(fee)) {
                setBillingFee(0.00);
                return;
            }

            setBillingFee(fee);
        }
    }

    const handleAllWorkload2 = (arrItem: string) => {
        let finalWorkload = 0;
        let finalWeek = '';
        for (const i in invoices.data) {
            if (invoices.data[i].workweek.includes(arrItem)) {
                finalWorkload += invoices.data[i].workload;
                finalWeek = invoices.data[i].workweek;
            }
        }
        
        if (finalWorkload !== 0) {
            return (
                <S.PanelInnerItem>
                    <p>{finalWeek}: {40 / 100 * finalWorkload} h</p>
                </S.PanelInnerItem>
            )
        }
    }

    useEffect(() => {
        setWorkload(0);
        let pensum = 0;
        for(const i in invoices.data) {
            if(!isNaN(invoices.data[i].workload)){
                pensum += invoices.data[i].workload;
            } else {
                pensum += 0;
            }
        }
        setWorkload(pensum);
    }, [invoices.data]);

    useEffect(() => {
        setCwArr(getCalendarWeek());
        calculateWorkFee();
    }, [workload]);

    return (
        /*<S.InvoiceRoleContainer>
            <S.InvoiceRoleTitle>{invoices.title}</S.InvoiceRoleTitle>
            <p>{workload} %</p>
            <p>{billingFee} CHF</p>
        </S.InvoiceRoleContainer>*/
        <S.InvoiceRoleCollapse ghost>
            <Panel 
                header={
                    <S.PanelTitle>
                        <b>{invoices.title}</b>
                        <p>{workload} %</p>
                        <p>{billingFee} CHF</p>
                    </S.PanelTitle>
                } 
                key={invoices.title}
            >

            {cwArr && cwArr.map((item: string) => {
                return (
                    handleAllWorkload2(item)
                )
            })}
            </Panel>
        </S.InvoiceRoleCollapse>
    );
};

export default InvoiceRoleFee;
