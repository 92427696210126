import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkweekArr } from '../../../services/api/calendarWeek/getCalenderWeek';
import routes from '../../../services/routing';
import routeConstants from '../../../services/routing/routes.constants';

import * as S from './RessourcePlanningTableSc';

type RessourcePlanningTableProps = {
    userList: string[];
    detailWeekWorkload: WorkweekArr;
};

const {
    workloads
} = routes;

const RessourcePlanningTable: React.FC<RessourcePlanningTableProps> = (data) => {
    const navigate = useNavigate();
    
    const navigateToPerson = (name: string) => {
        navigate(`/${routeConstants.base}${workloads.segment}/${name}`);
    };

    const getWorkweek = (week: string) => {
        let cwNbr = parseInt(week.slice(2,4));

        return 'KW-' + (cwNbr < 9 ? '0' + cwNbr : cwNbr);
    };

    const getTableCell = (user: string) => {
        let userCw1 = 0;
        let userCw2 = 0;
        let userCw3 = 0;
        let userCw4 = 0;
        let userCw5 = 0;
        let userCw6 = 0;
        let userCw7 = 0;
        let userCw8 = 0;

        let arr1 = data.detailWeekWorkload[Object.keys(data.detailWeekWorkload)[0] as keyof WorkweekArr];
        if (arr1 !== undefined) {
            userCw1 = arr1[arr1.findIndex(x => x.name === user)] === undefined ? 0 : arr1[arr1.findIndex(x => x.name === user)].workload;
        }
        let arr2 = data.detailWeekWorkload[Object.keys(data.detailWeekWorkload)[1] as keyof WorkweekArr];
        if (arr2 !== undefined) {
            userCw2 = arr2[arr2.findIndex(x => x.name === user)] === undefined ? 0 : arr2[arr2.findIndex(x => x.name === user)].workload;
        }
        let arr3 = data.detailWeekWorkload[Object.keys(data.detailWeekWorkload)[2] as keyof WorkweekArr];
        if (arr3 !== undefined) {
            userCw3 = arr3[arr3.findIndex(x => x.name === user)] === undefined ? 0 : arr3[arr3.findIndex(x => x.name === user)].workload;
        }
        let arr4 = data.detailWeekWorkload[Object.keys(data.detailWeekWorkload)[3] as keyof WorkweekArr];
        if (arr4 !== undefined) {
            userCw4 = arr4[arr4.findIndex(x => x.name === user)] === undefined ? 0 : arr4[arr4.findIndex(x => x.name === user)].workload;
        }
        let arr5 = data.detailWeekWorkload[Object.keys(data.detailWeekWorkload)[4] as keyof WorkweekArr];
        if (arr5 !== undefined) {
            userCw5 = arr5[arr5.findIndex(x => x.name === user)] === undefined ? 0 : arr5[arr5.findIndex(x => x.name === user)].workload;
        }
        let arr6 = data.detailWeekWorkload[Object.keys(data.detailWeekWorkload)[5] as keyof WorkweekArr];
        if (arr6 !== undefined) {
            userCw6 = arr6[arr6.findIndex(x => x.name === user)] === undefined ? 0 : arr6[arr6.findIndex(x => x.name === user)].workload;
        }
        let arr7 = data.detailWeekWorkload[Object.keys(data.detailWeekWorkload)[6] as keyof WorkweekArr];
        if (arr7 !== undefined) {
            userCw7 = arr7[arr7.findIndex(x => x.name === user)] === undefined ? 0 : arr7[arr7.findIndex(x => x.name === user)].workload;
        }
        let arr8 = data.detailWeekWorkload[Object.keys(data.detailWeekWorkload)[7] as keyof WorkweekArr];
        if (arr8 !== undefined) {
            userCw8 = arr8[arr8.findIndex(x => x.name === user)] === undefined ? 0 : arr8[arr8.findIndex(x => x.name === user)].workload;
        }

        return (
            <tr className={"personCell"} onClick={() => navigateToPerson(user)} key={user}>
                <td className="headP">{user}</td>
                <td className={userCw1 > 110 ? "overWorkloadTableCell" : "workloadTableCell"}>{userCw1}%</td>
                <td className={userCw2 > 110 ? "overWorkloadTableCell" : "workloadTableCell"}>{userCw2}%</td>
                <td className={userCw3 > 110 ? "overWorkloadTableCell" : "workloadTableCell"}>{userCw3}%</td>
                <td className={userCw4 > 110 ? "overWorkloadTableCell" : "workloadTableCell"}>{userCw4}%</td>
                <td className={userCw5 > 110 ? "overWorkloadTableCell" : "workloadTableCell"}>{userCw5}%</td>
                <td className={userCw6 > 110 ? "overWorkloadTableCell" : "workloadTableCell"}>{userCw6}%</td>
                <td className={userCw7 > 110 ? "overWorkloadTableCell" : "workloadTableCell"}>{userCw7}%</td>
                <td className={userCw8 > 110 ? "overWorkloadTableCell" : "workloadTableCell"}>{userCw8}%</td>
            </tr>
        )
    };

    return (
        <>
            <S.RessourceTableContainer>
                <S.RessourceTable>
                    <thead>
                        <tr>
                            <td className="empty"></td>
                            {Object.keys(data.detailWeekWorkload).map((cw) => {
                                return <td key={cw} className="headt">{getWorkweek(cw)}</td>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                            {
                                data.userList.map((user) => {
                                    return getTableCell(user);
                                })
                            }
                    </tbody>
                </S.RessourceTable>
            </S.RessourceTableContainer>
        </>
    );
};

export default RessourcePlanningTable;
