import { Progress } from 'antd';
import React from 'react';
import { WorkloadProps } from '../../../services/api/domain/issues/issues.types';

import * as S from './ProjectsPlanningProgressSc';

type ProjectPlanningWeekProps = {
    week: string;
    weekData: WorkloadProps[] | undefined;
};

const ProjectsPlanningProgress: React.FC<ProjectPlanningWeekProps> = (data) => {    
    return (
        <S.ProjectPlanningWeekContainer>
            <h1>{data.week}</h1>
            {
                data.weekData && data.weekData.length > 0 ? data.weekData.map((week, index) => {
                    return (
                        <S.ProjectPlanningWeek key={index}>
                            <h3>{week.name}</h3>
                            <h6>{week.role}</h6>
                            <Progress 
                                type="circle" 
                                percent={week.workload} 
                                format={() => `${week.workload} %`}
                                status="active"
                                strokeColor={
                                    week.workload > 74 && week.workload < 111 ? 
                                        '#52c41a' 
                                    : 
                                    week.workload > 110 ? 
                                        '#e9521b' 
                                    : 
                                        '#f9b75f'
                                }   
                            />
                        </S.ProjectPlanningWeek>
                    )
                }) : (
                    <>
                        <p>No Workload available</p>
                    </>
                )
            }
        </S.ProjectPlanningWeekContainer>
    );
};

export default ProjectsPlanningProgress;
