import React, { useEffect } from 'react';

import { Outlet } from 'react-router';

import * as S from './PageSc';

import { Layout, Menu, Breadcrumb } from 'antd';
import { UserOutlined, ProjectOutlined, PieChartOutlined } from '@ant-design/icons';
import {useLocation, useNavigate} from "react-router-dom";
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/store/rootReducer';
import { addProjects } from '../../../services/store/projects/projects.reducer';
import { Project } from '../../../services/api/domain/projects/projects.types';

import { MenuInfo } from 'rc-menu/lib/interface';
import routes from '../../../services/routing';
import routeConstants from '../../../services/routing/routes.constants';
import { api_getProjects, api_initProjects } from '../../../services/api/domain/projects/projects.queries';
import { api_getEmployees, api_initEmployees } from '../../../services/api/domain/employees/employee.queries';
import { addEmployees } from '../../../services/store/employees/employees.reducer';
import { Employee } from '../../../services/api/domain/employees/employee.types';
import PageHeader from '../pageHeader';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const {
    invoices,
    workloads
} = routes;

const Page: React.FC = () => {
    const { projects } = useSelector((state: RootState) => state.projects);
    const { employees } = useSelector((state: RootState) => state.employees);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const pathName = location.pathname;

    const handleHeaderNav = (clickEvent: MenuInfo) => {
        navigate(`/${routeConstants.base}${clickEvent.key}`);
    };

    const handleSideNavClick = (clickEvent: MenuInfo) => {
        if (pathName.includes('/invoices')) {
            navigate(`/${routeConstants.base}${invoices.segment}/${clickEvent.key}`);
        } else {
            if (clickEvent.key === 'all') {
                navigate(`/${routeConstants.base}${workloads.segment}`);
            } else {
                isNaN(Number(clickEvent.key)) ? (
                    navigate(`/${routeConstants.base}${workloads.segment}/${clickEvent.key}`)
                ) : (
                    navigate(`/${routeConstants.base}${workloads.segment}/project/${clickEvent.key}`)
                )
            }
        }
    }

    const handleInitProjectData = async () => {
        const data = await api_initProjects();
        dispatch(addProjects(data));
    };

    const handleInitEmployeeData = async () => {
        const data = await api_initEmployees();
        dispatch(addEmployees(data));
    }

    const handleProjectData = async () => {
        const data = await api_getProjects();

        if (data.length === 0) {
            handleInitProjectData();
            return;
        }

        dispatch(addProjects(data));
    };

    const handleEmployeeData = async () => {
        const data = await api_getEmployees();

        if (data.toString() === '') {
            handleInitEmployeeData();
            return;
        }

        dispatch(addEmployees(data));
    };

    useEffect(() => {
        if (projects.length === 0) {
            handleProjectData();
        }
        if (employees.length === 0) {
            handleEmployeeData();
        }
    });

    return (
            <S.PageContainer>
                <Header className="header topHeader">
                    <S.HeaderLogoContainer>
                        <img src={require('../../../assets/logos/logo_mit_text.png')} alt="Phoenix Systems AG Logo" />
                    </S.HeaderLogoContainer>

                    <Menu theme="dark" mode="horizontal" onClick={handleHeaderNav}>
                        <Menu.Item key="invoices" title="Invoices">
                            <span className={clsx([pathName.includes('/invoices') && 'isActive'])}>Invoices</span>
                        </Menu.Item>
                        <Menu.Item key="workloads" title="Workloads">
                            <span className={clsx([pathName.includes('/workloads') && 'isActive'])}>Worklaods</span>
                        </Menu.Item>
                    </Menu>

                    <PageHeader />
                </Header>
                <Layout>
                    <Sider width={200} className="site-layout-background">
                        <Menu
                        mode="inline"
                        style={{ height: '100%', borderRight: 0 }}
                        onClick={handleSideNavClick}
                        >
                            {pathName.includes('/invoices') ? (
                                projects && projects.length > 0 && projects.map((project: Project) => {
                                    return (
                                        <Menu.Item 
                                            key={project.project_id}
                                            title={project.project_name} 
                                            icon={<UserOutlined />}
                                        >
                                                <span>{project.project_name}</span>
                                        </Menu.Item>);
                                })
                            ) : (
                                <>
                                    <Menu.Item key="all" title="Weekly Ressource Planning" icon={<PieChartOutlined />}>
                                        Ressource Planning
                                    </Menu.Item>

                                    {employees && employees.length > 0 && (
                                        <SubMenu key="employeeSubMenu" icon={<UserOutlined />} title="Employees">
                                            {employees.map((user: Employee) => {
                                                return (<Menu.Item key={user.displayName} id="employee">{user.displayName}</Menu.Item>);
                                            })}
                                        </SubMenu>
                                    )}
                                    ,
                                    {projects && projects.length > 0 && (
                                        <SubMenu key="projectsSubMenu" icon={<ProjectOutlined />} title="Projects">
                                            {projects.map((project: Project) => {
                                                return (<Menu.Item key={project.project_id} id="project">{project.project_name}</Menu.Item>);
                                            })}
                                        </SubMenu>
                                    )}
                                </>
                                )
                            }
                        </Menu>
                    </Sider>
                    <Layout style={{ padding: '0 24px 24px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }}>
                            {pathName.split('/').map((path: string, index: number) => {
                                if (index === 1) {
                                    return (<Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>)
                                }
                                return (<Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>)
                            })}
                        </Breadcrumb>
                        <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                        >
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            </S.PageContainer>
        );
};

export default Page;
