import tw, { css, styled } from 'twin.macro';

type ButtonProps = {
    bgColor: string;
    isPdf?: boolean;
    disableBtn?: boolean;
};

export const InvoiceBtnContainer = styled.div(() => [
    css`
        ${tw`flex flex-row`}
    `
]);

export const invoiceBtn = styled.p(({bgColor, isPdf, disableBtn} :ButtonProps) => [
    css`
        padding: 8px 10px;
        max-width: 300px;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        text-align: center;
        font-size: 16px;
        margin-top: 50px;
        cursor: pointer;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        color: #FFF;
        background: ${bgColor};
        margin-right: 10px;
        margin-bottom: 20px;

        disableBtn && {
            cursor: not-allowed;
            border: 1px solid #999999;
            background: #cccccc;
            color: #666666;
        }
        `,

        disableBtn &&
            css`
                cursor: not-allowed;
                border: 1px solid #999999;
                background: #cccccc;
                color: #666666;
            `,
    
]);
