import React, { useEffect } from 'react';

import { useAuth } from 'react-oidc-context';
import { useImmer } from 'use-immer';
import ErrorAuthScreen from '../errorAuthScreen';

import SplashScreen from '../splashScreen';

type AuthProviderState = {
  isSilentRenew: boolean;
};

const AuthProviderInner: React.FC = ({ children }) => {
  const auth = useAuth();
  const [state, setState] = useImmer<AuthProviderState>({
    isSilentRenew: false
  });

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading && !auth.activeNavigator) {
      auth.signinRedirect();
      //auth.signinSilent();
    }

    if (auth.isAuthenticated && !state.isSilentRenew) {
      auth.startSilentRenew();
      //    auth.signinSilent();

      setState(draft => {
        draft.isSilentRenew = true;
      });
    }
  }, [auth, setState, state]);

  if (auth.isLoading) {
    return <SplashScreen />;
  }

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <SplashScreen />;
    case 'signoutRedirect':
      return <SplashScreen />;
  }

  if (auth.error) {
    return (
      <ErrorAuthScreen {...'Oops... ' + auth.error?.message} />
    );
  }

  if (!auth.isAuthenticated) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default AuthProviderInner;