import tw, { css, styled } from 'twin.macro';

export const RessourceWeekContainer = styled.div(() => [
    css`
        ${tw`sm:w-1/2 sm:mb-2.5 md:w-full md:mb-2.5 w-full`}

        && span.ant-progress-text {
            color: #000 !important;
        }
    `
]);

export const RessourceWeekOuterContainer = styled.div(() => [
    css`
        ${tw`flex flex-col mb-10`}

        width: calc(50% - 50px);

        && .weekWorklaodItem .ant-progress-text {
            color: #000 !important;
        }
    `
]);
